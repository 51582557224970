import "./actions";
import {
    AUTH_FIREBASE_AUTH, AUTH_FIREBASE_EMPTY,
    AUTH_FORM_DATA,
    AUTH_FORM_LOADING,
    AUTH_FORM_LOADING_TEXT,
    AUTH_FORM_PHONE_CONFIRMATION,
    AUTH_FORM_STEP_SET,
    AUTH_FORM_VISIBILITY,
    AUTH_PROVIDERS_REQUEST_SUCCESS,
    AUTH_RESET, AUTH_SET_PROFILE,
    AUTH_VARIANT_SELECTED,
} from "./actions";
import React from "react";
import {cleanupPhone, formatPhone, isPossibleEmail, isPossiblePhone} from "../../helpers";

const defaultState = {
    form: {
        username: '',
        password: '',
        code: '',
        shown: false,
        loading: false,
        step: 0,
        variant: '',
        loadingText: <span>&nbsp;</span>,
        possible: null,
    },
    firebase: {
        isLoaded: false,
        isEmpty: true,
        globalVariable: null,
    },
    profile: {},
    confirmation: {},
    providers: [],
};

export const reducer = (state = defaultState, action) => {

    switch (action.type) {
        case AUTH_FORM_VISIBILITY:
            return {...state, form: {...state.form, shown: action.shown}};
        case AUTH_FIREBASE_AUTH:
            return {...state, firebase: {...action.auth, isLoaded: true, isEmpty: false}};
        case AUTH_SET_PROFILE:
            return {...state, profile: {...action.profile, isLoaded: true, isEmpty: false}};
        case AUTH_FIREBASE_EMPTY:
            return {...state, profile: defaultState.profile, firebase: {...defaultState.firebase, isLoaded: true}}
        case AUTH_FORM_LOADING:
            return {...state, form: {...state.form, loading: action.loading}};
        case AUTH_FORM_LOADING_TEXT:
            return {...state, form: {...state.form, loadingText: action.loadingText}};
        case AUTH_FORM_DATA:
            if (Boolean(action.payload.username)) {
                if (isPossibleEmail(action.payload.username)) return {
                    ...state, form: {
                        ...state.form,
                        username: action.payload.username,
                        possible: 'email',
                    }
                };
                else if (isPossiblePhone(action.payload.username)) {
                    console.log('possible phone')
                    return {
                        ...state, form: {
                            ...state.form,
                            username: cleanupPhone(formatPhone(action.payload.username)),
                            possible: 'phone',
                        }
                    };

                }
            }
            return {
                ...state, form: {
                    ...state.form,
                    ...action.payload
                }
            };
        case AUTH_FORM_PHONE_CONFIRMATION:
            return {...state, confirmation: action.confirmation};
        case AUTH_PROVIDERS_REQUEST_SUCCESS:
            return {...state, providers: action.providers};
        case AUTH_VARIANT_SELECTED:
            return {...state, form: {...state.form, variant: action.variant}};
        case AUTH_FORM_STEP_SET:
            return {...state, form: {...state.form, step: action.step}};
        case AUTH_RESET:
            return {...state, form: defaultState.form};

        default:
            return state;
    }
};