import "./actions";
import {ALERTS_HIDE, ALERTS_SHOW, POPPER_HIDE, POPPER_SHOW} from "./actions";

const defaultState = {
    shown: false,
    message: '',
    type: 'info',
    popper: {
        popperAnchorEl: null,
        popperMessage: '',
        popperShown: false,

    }
};

export const reducer = (state = defaultState, action) => {
        switch (action.type) {
            case ALERTS_SHOW:
                return {...state, shown: true, type: action.alertType, message: action.message};
            case POPPER_SHOW:
                return {...state, popper: {...action.payload, popperShown: true}};
            case ALERTS_HIDE:
                return {...state, shown: false};
            case POPPER_HIDE:
                return {...state, popper: {...state.popper, popperShown: false}};


            default:
                return state;
        }
    }
;