// Профиль
export const PROFILE_DATA = 'PROFILE_DATA';
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_AVATAR_UPLOAD_REQUEST = 'PROFILE_AVATAR_UPLOAD_REQUEST';
export const PROFILE_AVATAR_UPDATE = 'PROFILE_AVATAR_UPDATE';
export const PROFILE_EDIT_BUTTON = 'PROFILE_EDIT_BUTTON';
export const PROFILE_SAVING = 'PROFILE_SAVING';
export const PROFILE_EDITING = 'PROFILE_EDITING';
export const PROFILE_EMAIL_CONFIRM_REQUEST = 'PROFILE_EMAIL_CONFIRM_REQUEST';
export const PROFILE_EMAIL_CHANGE_REQUEST = 'PROFILE_EMAIL_CHANGE_REQUEST';
export const PROFILE_EMAIL_CHANGE_DIALOG = 'PROFILE_EMAIL_CHANGE_DIALOG';
export const PROFILE_PHONE_CONFIRM_DIALOG = 'PROFILE_PHONE_CONFIRM_DIALOG';
export const PROFILE_PHONE_CONFIRM_REQUEST = 'PROFILE_PHONE_CONFIRM_REQUEST';
export const PROFILE_PHONE_CONFIRM = 'PROFILE_PHONE_CONFIRM';
export const PROFILE_PHONE_CONFIRM_CLEANUP = 'PROFILE_PHONE_CONFIRM_CLEANUP';

export const onAvatarUpload = (e) => ({type: PROFILE_AVATAR_UPLOAD_REQUEST, file: e.target.files[0]});
export const onEditSaveClick = () => ({type: PROFILE_EDIT_BUTTON});
export const onUpdateRequest = (e) => ({type: PROFILE_DATA, payload: {[e.target.name]: e.target.value}});

export const setUpdateRequested = (data) => ({type: PROFILE_UPDATE_REQUEST, data});
export const setEditing = (editing) => ({type: PROFILE_EDITING, editing});
export const setSaving = (saving) => ({type: PROFILE_SAVING, saving});
export const setData = (payload) => ({type: PROFILE_DATA, payload});

export const onEmailConfirmRequest = () => ({type: PROFILE_EMAIL_CONFIRM_REQUEST});
export const onPhoneConfirmRequest = () => ({type: PROFILE_PHONE_CONFIRM_REQUEST});
export const onPhoneConfirm = () => ({type: PROFILE_PHONE_CONFIRM});
export const onPhoneConfirmCleanup = () => ({type: PROFILE_PHONE_CONFIRM_CLEANUP});
export const onEmailChangeRequest = () => ({type: PROFILE_EMAIL_CHANGE_REQUEST});

export const setEmailChangeDialogShown = () => ({type: PROFILE_EMAIL_CHANGE_DIALOG, shown: true});
export const setEmailChangeDialogHidden = () => ({type: PROFILE_EMAIL_CHANGE_DIALOG, shown: false});
export const setPhoneConfirmDialogShown = () => ({type: PROFILE_PHONE_CONFIRM_DIALOG, shown: true});
export const setPhoneConfirmDialogHidden = () => ({type: PROFILE_PHONE_CONFIRM_DIALOG, shown: false});

