import React from 'react';
import {Grid, Avatar, IconButton} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVk, faGoogle, faFacebook} from "@fortawesome/free-brands-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthProviders} from "../../store/selectors";
import {makeStyles} from "@material-ui/core/styles";
import auth from "../../store/auth";

const Socials = () => {
    const classes = useStyles();
    const providers = useSelector(selectAuthProviders);
    const dispatch = useDispatch();
    const {onGoogleSignInRequest, onFacebookSignInRequest} = auth.actions(dispatch);

    return (
        <Grid container spacing={2} justify={'center'} style={{padding: '10px'}}>
            <Grid item xs={2}>
                <IconButton onClick={() => {
                    window.open('/__api/auth/vk', "_blank", "toolbar=0,location=0,menubar=0")

                    return true
                }}>
                    <Avatar variant={"rounded"}
                            className={providers && (providers.includes('vk')) ? classes.highlighted : null}>
                        <FontAwesomeIcon icon={faVk}/>
                    </Avatar>
                </IconButton>
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={onGoogleSignInRequest}>
                    <Avatar variant={"rounded"}
                            className={providers && (providers.indexOf('google.com') > -1) ? classes.highlighted : null}>
                        <FontAwesomeIcon icon={faGoogle}/>
                    </Avatar>
                </IconButton>
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={onFacebookSignInRequest}>
                    <Avatar variant={"rounded"}
                            className={providers && (providers.indexOf('facebook.com') > -1) ? classes.highlighted : null}>
                        <FontAwesomeIcon icon={faFacebook}/>
                    </Avatar>
                </IconButton>
            </Grid>
        </Grid>
    );
};


const useStyles = makeStyles((theme) => ({

    avatar: {
        cursor: 'pointer',
    },
    highlighted: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,

    }

}));
export default React.memo(Socials);