import "./actions";
import {
    UPLOADER_PROGRESS,
    UPLOADER_PROGRESS_SHOW,
    UPLOADER_TASK_ADD, UPLOADER_TASK_DONE,
    UPLOADER_TASKS_DONE
} from "./actions";

const defaultState = {
    file: null,
    path: null,
    progress: 0,
    shown: false,
    url: '',
    tasks: 0,
    working: false,
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPLOADER_PROGRESS:
            return {...state, progress: action.progress};
        case UPLOADER_PROGRESS_SHOW:
            return {...state, shown: action.shown};
        case UPLOADER_TASK_ADD:
            return {...state, tasks: state.tasks + 1, working: true};
        case UPLOADER_TASK_DONE:
            const tasks = state.tasks - 1;
            let working = true;
            if (tasks === 0) working = false;
            return {...state, tasks, working};
        case UPLOADER_TASKS_DONE:
            return {...state, working: false};
        default:

            return state;
    }
};