/* global grecaptcha */
import {select, all, takeEvery, put, fork} from 'redux-saga/effects';

import {auth, db, firebase} from "../../firebase";
import {
    onPhoneConfirmCleanup,
    PROFILE_AVATAR_UPLOAD_REQUEST,
    PROFILE_EDIT_BUTTON,
    PROFILE_EMAIL_CHANGE_REQUEST,
    PROFILE_EMAIL_CONFIRM_REQUEST, PROFILE_PHONE_CONFIRM,
    PROFILE_PHONE_CONFIRM_REQUEST,
    PROFILE_UPDATE_REQUEST,
    setData,
    setEditing,
    setEmailChangeDialogHidden,
    setPhoneConfirmDialogHidden,
    setPhoneConfirmDialogShown,
    setSaving,
    setUpdateRequested
} from "./actions";
import {doUpload, UPLOADER_SUCCESS} from "../uploader/actions";
import profile from "./index";
import {selectProfile} from "../selectors";
import {handleErrors, isPhone} from "../../helpers";
import {reloadUser} from "../auth/saga";


const alerts = require('../alerts/actions');

function* doAvatarUpload() {
    yield takeEvery(PROFILE_AVATAR_UPLOAD_REQUEST, function* (action) {
        try {
//            const state = yield select();

            const {file} = action;
            if (!file) return;
            const uid = yield auth.currentUser.uid;
            const path = `userData/${uid}/avatarImage`;
            yield put(doUpload(file, path, 'profile'));
//            yield put({type: 'PROFILE_AVATAR_UPDATE', url});
//            console.log('Got file url: ', url);
        } catch (e) {
            yield* handleErrors(e)
        }
    })
}

function* doAvatarUpdate() {
    yield takeEvery(UPLOADER_SUCCESS, function* (action) {
        try {
            const {url, sender} = action;
            if (sender === 'profile') yield put(setUpdateRequested({avatarUrl: url}));

        } catch (e) {
            yield* handleErrors(e)
        }
    })
}

function* onEditClicked() {
    yield takeEvery(PROFILE_EDIT_BUTTON, function* (action) {

        try {
            //const profileState = yield select(profile.getState);
            const profileState = yield profile.selector;
            const {displayName, email, phone} = profileState;
            yield put(setSaving(true));
            yield put(setUpdateRequested({displayName, email, phone}));
            yield put(setEditing(false));
            yield put(setSaving(false));

        } catch (e) {

            yield* handleErrors(e)
        }
    })
}

function* onUpdateRequest() {
    yield takeEvery(PROFILE_UPDATE_REQUEST, function* (action) {

        try {
            const uid = yield auth.currentUser.uid;
            const docRef = db.collection("Users").doc(uid);
            return yield docRef.set({...action.data}, {merge: true});
        } catch (e) {
            yield* handleErrors(e)
        }
    })
}

function* onEmailChangeRequest() {
    yield takeEvery(PROFILE_EMAIL_CHANGE_REQUEST, function* (action) {

        try {
            yield auth.useDeviceLanguage();
            const {email} = yield select(selectProfile);
            yield auth.currentUser.updateEmail(email);
            yield put(alerts.show('info', 'Письмо с подтверждением отпралено на ваш ящик'));
            yield put(setEmailChangeDialogHidden());
//            yield put(onConflictDetect());
            yield* reloadUser();

        } catch (e) {
            yield* handleErrors(e)
        }
    })
}

function* onPhoneConfirmRequest() {
    yield takeEvery(PROFILE_PHONE_CONFIRM_REQUEST, function* (action) {

        try {
            yield auth.useDeviceLanguage();
            const {phone} = yield select(selectProfile);
            if (phone === '+71234567890') auth.settings.appVerificationDisabledForTesting = true;
            if (!isPhone(phone)) {
                yield put(alerts.show('warning', 'Проверьте правильность ввода номера телефона'));
                return null
            }
            yield put(setSaving(true));

            const recaptchaDiv = document.getElementById('recaptcha-container');
            if (recaptchaDiv.childElementCount === 0) {
                window.recaptchaVerifier = undefined;
                window.recaptchaWidgetId = undefined;
            }
            if (window.recaptchaVerifier) {
                grecaptcha.reset(window.recaptchaWidgetId);
            } else {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {'size': 'invisible'});
                window.recaptchaWidgetId = yield window.recaptchaVerifier.render();
            }
            const appVerifier = yield window.recaptchaVerifier;
//            yield appVerifier.render();
            const confirmation = yield auth.currentUser.linkWithPhoneNumber(phone, appVerifier);
            yield put(setData({confirmation, phoneConfirmationReady: true}));
            yield put(setSaving(false));
            yield put(setPhoneConfirmDialogShown());
        } catch (e) {
            yield put(setSaving(false));
            grecaptcha.reset(window.recaptchaWidgetId);
            yield* handleErrors(e)
        }
    })
}

function* onPhoneConfirm() {
    yield takeEvery(PROFILE_PHONE_CONFIRM, function* (action) {

        try {
            yield put(setSaving(true));
            const {code, confirmation} = yield select(selectProfile);
            yield confirmation.confirm(code);
            yield put(onPhoneConfirmCleanup());
            yield put(setSaving(false));
            yield put(alerts.show('success', 'Номер телефона успешно подтвержден'));
            yield reloadUser();

        } catch (e) {
            yield put(setSaving(false));
            if (e.code === 'auth/credential-already-in-use') {
                yield put(setPhoneConfirmDialogHidden());
                yield put(onPhoneConfirmCleanup());
                yield put(setData({phone: '',}));
            }
            yield* handleErrors(e)
        }
    })
}

function* onEmailConfirmRequested() {
    yield takeEvery(PROFILE_EMAIL_CONFIRM_REQUEST, function* (action) {
        try {
            const user = yield auth.currentUser;
            yield auth.useDeviceLanguage();
            yield user.sendEmailVerification({
                url: 'https://konkurs2020.karaoke-enjoy.ru/__api/auth/email/?email=' + auth.currentUser.email
            });


        } catch (e) {
            yield* handleErrors(e)
        }
    })
}


export function* saga() {
    const sagas = [
        doAvatarUpload,
        doAvatarUpdate,
        onEditClicked,
        onUpdateRequest,
        onEmailConfirmRequested,
        onEmailChangeRequest,
        onPhoneConfirmRequest,
        onPhoneConfirm
    ];
    yield all(sagas.map(saga =>
        fork(saga)
    ));


}