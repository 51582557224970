import React from 'react';
import {Badge, Avatar, Container, TextField, Button, IconButton, Grid} from "@material-ui/core";
import {ExitToApp, Save, PhotoCamera, Email, Sms} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFirebaseProfileCompletion, selectIsAccountConfirmed,
    selectProfile, selectProfileIsEditing,
    selectUploader
} from "../store/selectors";
import ProgressWrap from "../containers/ProgressWrap";
import profile from "../store/profile";
import AppDialog from "./AppDialog";
import auth from "../store/auth";

const Profile = () => {
    const handleUploadClick = () => document.getElementById('avatarFile').click();


    const classes = useStyles();
    const dispatch = useDispatch();
    const {shown, progress} = useSelector(selectUploader);
    const {
        email, displayName, phone, avatarUrl, saving, code,
        emailChangeDialogShown, phoneConfirmDialogShown, phoneConfirmationReady
    } = useSelector(selectProfile);
    const isEditing = useSelector(selectProfileIsEditing);
    const {
        onEditSaveClick, onAvatarUpload, onUpdateRequest,
        onEmailConfirmRequest, onEmailChangeRequest,
        setEmailChangeDialogShown, setEmailChangeDialogHidden,
        onPhoneConfirm, setPhoneConfirmDialogHidden,
        onPhoneConfirmRequest
    } = profile.actions(dispatch);
    const {onLogOut} = auth.actions(dispatch)
    const uncompletedValues = useSelector(selectFirebaseProfileCompletion);
    const isConfirmed = useSelector(selectIsAccountConfirmed);
    const avatarUpload =
        <IconButton color={"secondary"} onClick={handleUploadClick} size={"small"}>
            <Avatar className={classes.avatarUpload}>
                <ProgressWrap shown={shown} progress={progress}>
                    <PhotoCamera/>
                </ProgressWrap>
            </Avatar>
        </IconButton>
    ;

    return (
        <div>
            <Container>
                <div className={classes.exitButtonContainer}>
                    <Button onClick={onLogOut} size={"small"}>
                        <ExitToApp/> <p> Выйти из профиля</p>
                    </Button>
                </div>
            </Container>
            <div className={classes.avatarWrap}>
                <Badge overlap="circle"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                       }}
                       badgeContent={avatarUpload}>
                    <Avatar src={avatarUrl} className={classes.avatar}>
                        {displayName && displayName.charAt(0)}
                    </Avatar>
                </Badge>
            </div>
            <input type="file" name="avatarFile" id="avatarFile" hidden onChange={onAvatarUpload}/>
            <Grid container spacing={2} justify='center' style={{padding: '15px'}}>
                <Grid item xs={7}>
                    <TextField label="Имя" fullWidth value={displayName}
                               autoComplete='off'
                               name="displayName"
                               onChange={onUpdateRequest} disabled={saving}
                               error={(uncompletedValues.includes('displayName') && !displayName) || !displayName}/>
                </Grid>
                <Grid item xs={3}>
                    {isEditing && <Badge
                        overlap="rectangle"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        style={{bottom: '15px'}}
                        badgeContent={<Button
                            startIcon={<Save/>}
                            size={"small"}
                            onClick={onEditSaveClick}>
                            Сохранить
                        </Button>}>&nbsp;</Badge>}
                </Grid>
                <Grid item xs={7}>
                    <TextField label="E-Mail" fullWidth value={email} name="email" onChange={onUpdateRequest}
                               disabled={true}/>
                </Grid>
                <Grid item xs={3}><Badge
                    overlap="rectangle"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    style={{bottom: '15px'}}
                    badgeContent={<Button
                        startIcon={<Email/>}
                        size={"small"}
                        onClick={isConfirmed ? setEmailChangeDialogShown : onEmailConfirmRequest}>
                        {isConfirmed ? "Изменить" : "Подтвердить"}
                    </Button>}>&nbsp;</Badge></Grid>


                <Grid item xs={7}>
                    {(!isConfirmed || Boolean(phone)) &&
                    <TextField label="Номер мобильного" fullWidth value={phone} name="phone"
                               autoComplete={'off'}
                               onChange={onUpdateRequest}
                               disabled={isConfirmed}/>}
                </Grid>
                <Grid item xs={3}>{!isConfirmed &&

                <Badge
                    overlap="rectangle"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    style={{bottom: '15px'}}
                    badgeContent={
                        <ProgressWrap shown={saving && !phoneConfirmDialogShown} color={"secondary"}>
                            <Button
                                startIcon={<Sms/>}
                                size={"small"}
                                disabled={saving}
                                onClick={onPhoneConfirmRequest}
                            >
                                Подтвердить
                            </Button>
                        </ProgressWrap>
                    }>&nbsp;</Badge>}
                </Grid>

            </Grid>
            <div className={classes.exitButtonContainer}>
                {isConfirmed ? "Аккаунт подтвержден" : "Аккаунт не подтвержден"}
            </div>
            <AppDialog open={emailChangeDialogShown} onAction={onEmailChangeRequest}
                       onClose={setEmailChangeDialogHidden}
                       value={email} type={'email'} title={'E-Mail'} name={'email'}
                       text={'Введите новый адрес'} changeValue={onUpdateRequest}/>
            <AppDialog open={phoneConfirmDialogShown} onAction={onPhoneConfirm}
                       onClose={setPhoneConfirmDialogHidden}
                       value={code} type={'text'} title={'Код подтверждения'} name={'code'}
                       text={'Введите код из SMS'}
                       addicts={<ProgressWrap shown={saving} color={"secondary"}>&nbsp;</ProgressWrap>}
                       disabled={!phoneConfirmationReady || saving}
                       changeValue={onUpdateRequest}/>
            <div id="recaptcha-container"></div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    textFieldsContainer: {
        padding: theme.spacing(4),
    },
    avatarWrap: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',

    },
    exitButtonContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    avatarOverlay: {
        left: '50%',
        top: '50%',
        marginTop: -12,
        marginLeft: -12,
        position: "absolute"
    },
    avatarUpload: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    }

}));
export default React.memo(Profile);