import {all, takeEvery, put, fork, select} from 'redux-saga/effects';
import {firebase, storage,} from "../../firebase";
import {eventChannel} from "redux-saga";
import {
    addTask,
    removeTask,
    setProgress,
    setProgressShown, tasksDone,
    UPLOADER_TASK_DONE,
    UPLOADER_UPLOAD_FILE
} from "./actions";
import {handleErrors} from "../../helpers";

function* doUpdateProgress(snapshot) {
    yield put(setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
}

//TODO: Придумать как разделить аплоадеры
function* doUploadFile() {
    yield takeEvery(UPLOADER_UPLOAD_FILE, function* (action) {
        try {
            const {file, path, sender = 'DEFAULT', monitor} = action;
            const uploadTask = storage.ref(path).put(file);
            yield put(addTask());
            if (monitor) {
                yield put(setProgress(0));
                yield put(setProgressShown(true));
                const channel = eventChannel(e => uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, e));
                yield takeEvery(channel, doUpdateProgress);
            }
            //            console.log('Upload task: uploading...');
            yield uploadTask;
//            console.log('Upload task: getting URL');
            const url = yield uploadTask.snapshot.ref.getDownloadURL();
//            console.log('Upload task completed');
            if (monitor) {
                yield put(setProgressShown(false));
                yield put(setProgress(0));
            }
            yield put({type: 'UPLOADER_SUCCESS', url, sender});
            yield put(removeTask());
        } catch (e) {
            yield put(setProgressShown(false));
            yield put(setProgress(0));
            yield* handleErrors(e);
        }
    })
}

function* onTaskDone() {
    yield takeEvery(UPLOADER_TASK_DONE, function* (action) {
        const state = yield select();
        const tasks = state.uploader.tasks;
        if (tasks === 0) yield put(tasksDone());
    })
}


export function* saga() {
    const sagas = [
        doUploadFile,
        onTaskDone
//        progress
    ];
    yield all(sagas.map(saga =>
        fork(saga)
    ));


}