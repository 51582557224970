/* global grecaptcha */
import {cancel, take, call, all, takeEvery, put, fork, select} from 'redux-saga/effects';
import {
    AUTH_EMAIL_SIGN_IN,
    AUTH_EMAIL_SIGN_UP,
    AUTH_FORM_IS_EMAIL,
    AUTH_FORM_IS_PHONE,
    AUTH_FORM_PHONE_CONFIRM,
    AUTH_FORM_VALIDATE,
    AUTH_PROVIDERS_REQUEST_SUCCESS,
    AUTH_SUCCESS,
    setLoading,
    setFormVariant,
    setFromStep,
    setProviders,
    setLoadingText,
    setFormShown,
    setFormHidden,
    setPhoneConfirmation,
    setStateReset,
    AUTH_LOGIN_GOOGLE_REQUEST,
    setAuthSuccess,
    AUTH_LOGIN_FACEBOOK_REQUEST, setFirebaseAuth, setFirebaseEmpty, AUTH_LOGOUT, setProfile, AUTH_FIREBASE_AUTH
} from "./actions";

import {functions, firebase, auth, db} from "../../firebase";
import {handleErrors, isEmail, isPhone, reduxSagaFirebase} from "../../helpers";
import {selectAuthForm, selectUserId} from "../selectors";

const alerts = require('../alerts/actions');

function* profileChangesListener(channel) {
    yield takeEvery(channel, function* (profile) {
        const profileData = yield profile.data()
        if (profileData.updated) yield reloadUser();
        else yield put(setProfile(profileData));
    });
}

function* listenFirebaseProfile() {
    while (yield take(AUTH_FIREBASE_AUTH)) {
        const uid = yield select(selectUserId);
        const profileRef = yield db.collection('Users').doc(uid);
        const channel = reduxSagaFirebase.firestore.channel(profileRef, 'document');
        const profile = yield take(channel)
        yield put(setProfile(profile.data()));
        const listener = yield fork(profileChangesListener, channel)
        yield take(AUTH_LOGOUT);
        yield cancel(listener)
    }
}


function* watchAuthState() {
    /*    const authChannel = eventChannel(emit =>
            auth.onAuthStateChanged(user => emit({user})));
        yield takeEvery(authChannel, (user) => {
            console.log(user)
        });*/
    const channel = yield call(reduxSagaFirebase.auth.channel);
    yield takeEvery(channel, function* (user) {
// TODO: Запомнить как передавать аргумент стерлочной функции
//        window.firebaseAuth = user;
        if (user.user)
            yield put(setFirebaseAuth(user.user.toJSON()/*Object.fromEntries(Object
                .entries(user.user)
                .filter(field => (([key]) => key.length > 2)(field)))*/))
        else yield put(setFirebaseEmpty())
    });
}

export function* reloadUser() {
    yield auth.currentUser.reload();
    const docRef = db.collection("Users").doc(auth.currentUser.uid);
    yield docRef.set({updated: firebase.firestore.FieldValue.delete()}, {merge: true});
    const updatedUser = auth.currentUser.toJSON();
    yield put(setFirebaseAuth(updatedUser));
}

function* onUsernameValidate() {
    yield takeEvery(AUTH_FORM_VALIDATE, function* (action) {


        try {
            yield put(setLoadingText('Проверка...'));
            yield put(setLoading(true));
            const state = yield select();
            const {username} = state.auth.form;
            if (isPhone(username)) yield put({type: AUTH_FORM_IS_PHONE});
            else if (isEmail(username)) yield put({type: AUTH_FORM_IS_EMAIL});
            else {
                yield put(alerts.show('error', 'Укажите E-Mail или номер телефона'));
                yield put(setLoading(false));
            }
        } catch (e) {
            yield put(setLoading(false));
            yield* handleErrors(e)
        }

    })
}

function* onEmailCheck() {
    yield takeEvery(AUTH_FORM_IS_EMAIL, function* (action) {
        try {
            const state = yield select();
            const {username} = state.auth.form;
            const task1 = auth.fetchSignInMethodsForEmail(username);
            const task2 = functions.httpsCallable('checkVk');
            yield put({type: 'AUTH_PROVIDERS_REQUEST'});
            const {vk, gotProviders} = yield all({
                gotProviders: task1,
                vk: call(task2, {username})
            });
            let providers = [...gotProviders];
            if (vk.data) providers.push('vk');
            yield put(setProviders(providers));
            yield put(setLoading(false));
        } catch (e) {
            yield* handleErrors(e)
        }

    })
}

function* onPickVariant() {
    yield takeEvery(AUTH_PROVIDERS_REQUEST_SUCCESS, function* (action) {
        try {
            const state = yield select();
            const {providers} = state.auth;
            let variant;
            if (providers.length > 0) {
                if (providers.indexOf('vk') > -1 || providers.indexOf('google.com') > -1 || providers.indexOf('facebook.com') > -1) variant = 'auth-provider';
                else if (providers.indexOf('password') > -1) variant = 'auth-email';
            } else variant = 'new-email';

            yield put(setFormVariant(variant));
            yield put(setFromStep(1));
        } catch (e) {
            yield* handleErrors(e)
        }

    })
}

function* onEmailSignIn() {
    yield takeEvery(AUTH_EMAIL_SIGN_IN, function* (action) {
        try {
            yield put(setLoading(true));
            const {username: email, password} = yield select(selectAuthForm);
            yield put(setFormShown());
            const data = yield call(reduxSagaFirebase.auth.signInWithEmailAndPassword, email, password);
//            yield auth.signInWithEmailAndPassword(email, password);
            yield put(setAuthSuccess(data));
        } catch (e) {
            yield put(setFormShown());
            yield* handleErrors(e)
        }

    })
}

function* onEmailSignUp() {
    yield takeEvery(AUTH_EMAIL_SIGN_UP, function* (action) {
        try {
            yield put(setLoading(true));
            const state = yield select();
            const {username: email, password} = state.auth.form;
            yield put(setFormHidden());
            yield auth.createUserWithEmailAndPassword(email, password);
            yield put({type: 'AUTH_SUCCESS'});
        } catch (e) {
            yield put(setFormShown());
            yield* handleErrors(e)
        }

    })
}

function* onAuthSuccess() {
    yield takeEvery(AUTH_SUCCESS, function* (action) {
        try {
            yield put(setStateReset());
            yield put(alerts.show('success', 'Вы успешно авторизовались!'));
        } catch (e) {
            yield* handleErrors(e)
        }

    })
}

export function* onPhoneCheck() {
    yield takeEvery(AUTH_FORM_IS_PHONE, function* (action) {
        try {
            // '+71234567890'
            const state = yield select();
            const phone = state.auth.form.username;
            console.log('creating captcha');
            yield put(setLoadingText('Загрузка капчи...'));
            if (!window.recaptchaVerifier) window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {'size': 'invisible'});
            console.log('captcha created');
            const appVerifier = window.recaptchaVerifier;
            yield appVerifier.render();
            yield put(setLoadingText('Отправка кода...'));
            const confirmation = yield auth.signInWithPhoneNumber(phone, appVerifier);
            yield put(setPhoneConfirmation(confirmation));
            yield put(setFormVariant('phone'));
            yield put(setFromStep(1));
            yield put(setLoading(false));

        } catch (e) {
            grecaptcha.reset(window.recaptchaWidgetId);
            yield* handleErrors(e)
        }

    })
}

export function* onPhoneConfirm() {
    yield takeEvery(AUTH_FORM_PHONE_CONFIRM, function* (action) {
        try {
            yield put(setLoading(true));
            const state = yield select();
            const {code} = state.auth.form;
            const {confirmation} = state.auth;
            yield put(setFormHidden());
            yield confirmation.confirm(code);
            yield put(setLoading(false));
            yield put({type: 'AUTH_SUCCESS'});
        } catch (e) {
            yield put(setFormShown());
            yield* handleErrors(e)
        }
    })
}

function* onGoogleSignIn() {
    yield takeEvery(AUTH_LOGIN_GOOGLE_REQUEST, function* (action) {
        try {
            const authProvider = new firebase.auth.GoogleAuthProvider()
            const data = yield call(reduxSagaFirebase.auth.signInWithPopup, authProvider)
            yield put(setAuthSuccess(data))
        } catch (e) {
            yield put(setFormShown());
            yield* handleErrors(e)
        }
    })
}

function* onFacebookSignIn() {
    yield takeEvery(AUTH_LOGIN_FACEBOOK_REQUEST, function* (action) {
        try {
            const authProvider = new firebase.auth.FacebookAuthProvider()
            const data = yield call(reduxSagaFirebase.auth.signInWithPopup, authProvider)
            yield put(setAuthSuccess(data))
        } catch (e) {
            yield put(setFormShown());
            yield* handleErrors(e)
        }
    })
}

function* onLogout() {
    yield takeEvery(AUTH_LOGOUT, function* (action) {
        try {
            yield call(reduxSagaFirebase.auth.signOut)

        } catch (e) {
            yield* handleErrors(e)
        }
    })
}

export function* saga() {
    const sagas = [
        onUsernameValidate,
        onEmailCheck,
        onPickVariant,
        onEmailSignIn,
        onEmailSignUp,
        onAuthSuccess,
        onPhoneCheck,
        onPhoneConfirm,
        watchAuthState,
        onGoogleSignIn,
        onFacebookSignIn,
        onLogout,
        listenFirebaseProfile

    ];
    yield all(sagas.map(saga =>
        fork(saga)
    ));


}