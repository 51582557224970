import {createStore, applyMiddleware, compose} from 'redux';
//import {createBrowserHistory} from "history";
//import {routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from "./saga";
import createRootReducer from "./reducers";

//export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}


export default function configureStore(preloadedState) {
    const store = createStore(
//        createRootReducer(history), // root reducer with router state
        createRootReducer(), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
//                routerMiddleware(history), // for dispatching history actions
                sagaMiddleware,
            ),
            ...enhancers
        ),
    );
    sagaMiddleware.run(rootSaga);
    return store
}


