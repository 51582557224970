import React from 'react';
import {CssBaseline, darken, fade, lighten, useMediaQuery} from "@material-ui/core";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';


import PropTypes from 'prop-types';
/*
input:-internal-autofill-selected
input:-webkit-autofill {
    background-color: #FAFFBD !important;
}
 */
const MyTheme = (props) => {
        const {children, brightness} = props;
        let isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
        switch (brightness) {
            case 'day':
                isDarkMode = false;
                break;
            case 'night':
                isDarkMode = true;
                break;
            default:
        }

        const themeTemplate = {
            overrides: {
                socialIcons: {
                    display: "flex",
                },
                MuiGrid: {
                    item: {
                        justifyContent: 'center',
                        display: 'flex',
                    }
                },
            },
            palette: {
                /*primary: {main: '#26381e', contrastText: '#ffcb84'},
                secondary: {main: '#ffcb84', contrastText: '#ffffff'},*/
                primary: {main: '#263d1e', contrastText: '#F1B86E'},
                secondary: {main: '#F1B86E', contrastText: '#fff'},
            },
            themeName: 'Enjoy!',
        };
        const lightTheme = {
            ...themeTemplate, palette: {
                ...themeTemplate.palette,
                primary: {main: '#F1B86E', contrastText: '#75191C'},
                secondary: {main: '#75191C',/* contrastText: '#fff'*/},
                type: 'light',
                text: {
                    primary: '#75191C',
                },
                action: {
                    active: '#75191C',
                }


                /*             background: {
                                 default: lighten(themeTemplate.palette.secondary.main, 0.9),
                                 paper: lighten(themeTemplate.palette.secondary.main, 0.7)
                             },
             */

            }
        };
        const darkTheme = {
                ...themeTemplate,
                palette: {
                    ...themeTemplate.palette,
                    type: 'dark',
                    text: {
                        primary: themeTemplate.palette.secondary.main,

                    },
                    action: {
                        active: themeTemplate.palette.secondary.main,

                    }
                },
                /*overrides: {
                    ...themeTemplate.overrides,
                    MuiLink: {
                        root: {
                            color: lighten(themeTemplate.palette.secondary.main, 0.5),
                        }
                    }
                },*/
            }
        ;
        const theme = createMuiTheme(isDarkMode ? darkTheme : lightTheme);
        if (isDarkMode) {
            theme.palette.background.default = darken(theme.palette.primary.main, 0.3);
            theme.palette.background.paper = darken(theme.palette.primary.main, 0.1);
            theme.palette.text.secondary = fade(theme.palette.text.primary, 0.7);
            theme.palette.text.disabled = fade(theme.palette.text.primary, 0.5);
            theme.palette.action.focus = theme.palette.secondary.main;
            theme.overrides.MuiLink = {
                root: {
                    color: lighten(theme.palette.text.primary, 0.5)
                }
            };

        } else {
            theme.palette.type = "light";
            theme.palette.background.default = lighten(theme.palette.primary.main, 0.8);
            theme.palette.background.paper = lighten(theme.palette.primary.main, 0.7);
            theme.palette.text.secondary = fade(theme.palette.text.primary, 0.7);
            theme.palette.text.disabled = fade(theme.palette.text.primary, 0.5);
            theme.palette.action.focus = theme.palette.secondary.main;
            theme.overrides.MuiLink = {
                root: {
                    color: darken(theme.palette.text.primary, 0.4)
                }
            };

        }


        //theme.palette.background.default = '#172612';
        //theme.palette.background.default = darken(theme.palette.primary.main, 0.2);
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        );
    }
;

MyTheme.propTypes = {
    children: PropTypes.node.isRequired,
};

export default React.memo(MyTheme);