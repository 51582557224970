import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {select} from 'redux-saga/effects';

const moduleName = 'clips';                       // Менять
const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
const getState = state => state[moduleName];
const selector = select(getState);
const getBindedActions = dispatch => bindActionCreators(actions, dispatch);
const connectComponent = connect(getState, getBindedActions);
export const clips = {                             // Менять
    ...actions,
    reducer,
    saga,
    selector,
    getState,
    getBindedActions,
    connect: connectComponent,
    actions: dispatch => bindActionCreators(actions, dispatch)
};
export default clips;                              // Менять


