import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Badge,
    IconButton,
    List,
    Link,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText, SwipeableDrawer
} from "@material-ui/core";
import {Menu, Subject, LockOpen, BrightnessAuto, Brightness4, BrightnessHigh} from "@material-ui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons/faCrown";
import {useDispatch, useSelector} from "react-redux";
import {selectFirebaseProfile, selectIsAuthenticated} from "../store/selectors";
import auth from "../store/auth";
import AppModal from "../containers/AppModal";
import Rules from "./Rules";

const LeftMenu = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {displayName, avatarUrl, admin} = useSelector(selectFirebaseProfile);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const dispatch = useDispatch();
    const {setFormShown} = auth.actions(dispatch);
    const [rulesOpen, setRulesOpen] = React.useState(false);
    const {toggleBrightness, brightness} = props;

    const InnerMenu = () => (
            <div
                className={classes.list}
                role="presentation"
                onClick={() => false /*setOpen(false)*/}
                //            onKeyDown={toggleDrawer(anchor, false)}
            >

                <List component="nav" aria-label="profile">
                    {isAuthenticated ?
                        <ListItem button onClick={setFormShown}>
                            <ListItemIcon>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={admin && <FontAwesomeIcon icon={faCrown}/>}
                                >
                                    <Avatar src={avatarUrl}>
                                        {displayName ? displayName.charAt(0) : null}
                                    </Avatar>
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Открыть профиль" secondary={displayName}/>
                        </ListItem>
                        :
                        <ListItem button onClick={setFormShown}>
                            <ListItemIcon>
                                <LockOpen/>
                            </ListItemIcon>
                            <ListItemText primary="Авторизация"/>
                        </ListItem>}
                </List>
                <Divider/>
                <List component="nav" aria-label="profile">
                    <ListItem button onClick={() => {
                        setRulesOpen(true)
                        setOpen(false);
                    }}>
                        <ListItemIcon>
                            <Subject/>
                        </ListItemIcon>
                        <ListItemText primary="Условия участия"/>
                    </ListItem>
                    <ListItem button onClick={toggleBrightness}>
                        <ListItemIcon>
                            {{auto: <BrightnessAuto/>, day: <BrightnessHigh/>, night: <Brightness4/>}[brightness]}
                        </ListItemIcon>
                        <ListItemText primary={`Тема: ${{auto: 'авто', day: 'светлая', night: 'темная'}[brightness]}`}/>
                    </ListItem>
                </List>
                <Divider/>

                <div className={classes.aboutDeveloper}>
                    <p>Developed by <Link component="button"
                                          onClick={() => window.open("http://t.me/aleksyuk", '_blank')}
                                          variant="body2"
                    >@aleksyuk</Link></p>
                </div>


            </div>
        )
    ;


    return (
        <div style={{paddingRight: '10px'}}>
            <IconButton onClick={() => setOpen(true)}>
                <Menu/>
            </IconButton>
            <SwipeableDrawer onOpen={() => setOpen(true)} anchor={"left"} open={open} onClose={() => setOpen(false)}>
                <InnerMenu/>
            </SwipeableDrawer>
            <AppModal maxWidth={"md"} open={rulesOpen} onClose={() => setRulesOpen(false)} title="Правила участия">
                <Rules/>
            </AppModal>
        </div>


    );
}

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    aboutDeveloper: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',

    }
}));
export default React.memo(LeftMenu);