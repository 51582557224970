import React from 'react';
import PropTypes from 'prop-types';
import {Backdrop, Container, Modal, IconButton, Typography, Toolbar, Box, AppBar, Paper} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
    },
}));

const AppModal = props => {
    const classes = useStyles();
    const {open, onClose, children, title} = props;
    let {maxWidth} = props;
    if (maxWidth === undefined) maxWidth = "xs";
    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Container maxWidth={maxWidth} style={{outline: 'none'}}>
                <Paper className={classes.paper}>
                    <AppBar position="static">
                        <Toolbar>
                            <Box flexGrow={1}>
                                <Typography
                                    align={"center"}
                                    variant={"h5"}
                                >{title}
                                </Typography>
                            </Box>
                            <IconButton size={"small"} color={"default"} onClick={onClose}>
                                <HighlightOff/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {children}

                </Paper>
            </Container>
        </Modal>
    );
};

AppModal.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
};

export default React.memo(AppModal);