import React from 'react';
import MuiAlert from "@material-ui/lab/Alert";
import {useDispatch, useSelector} from "react-redux";
import alerts from "../store/alerts";
import {Container, Fade, Popper, Snackbar, Paper} from "@material-ui/core";
import {selectAlerts, selectAlertsPopper} from "../store/selectors";

const Alerts = () => {
    const {message, shown, type} = useSelector(selectAlerts);
    const {popperAnchorEl, popperMessage, popperShown} = useSelector(selectAlertsPopper);
    const popperId = popperShown ? 'transitions-popper' : undefined;
    const dispatch = useDispatch();
    const {hide, hidePopper} = alerts.actions(dispatch);
    return (
        <div>
            <Snackbar open={shown && message !== ''} autoHideDuration={6000}
                      onClose={hide}>
                <MuiAlert variant="filled" severity={type} onClose={hide}>
                    {message}
                </MuiAlert>
            </Snackbar>
            <Popper id={popperId} open={popperShown} anchorEl={popperAnchorEl}
                    onClick={hidePopper}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                    }}
                    transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Container maxWidth={"xs"}>
                            <Paper elevation={6}>
                                <div style={{padding: "10px"}}>
                                    {popperMessage}
                                </div>
                            </Paper>
                        </Container>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default React.memo(Alerts);