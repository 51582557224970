import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardActions, CardHeader, CardMedia} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {useSelector} from "react-redux";
import {getClipById} from "../store/selectors";
import ClipButtons from "./snippets/ClipButtons";

const useStyles = makeStyles((theme) => ({
    clipMedia: {
        height: '230px',
        width: '100%',
        objectFit: 'cover',
    }
}));

const Clip = (props) => {

    //const {id} = props;
    const id = React.useMemo(() => props.id, [props.id]);
    const clip = useSelector(getClipById(id));
    const {title, videoURL, thumbnailURL, user} = clip;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar src={user && user.avatarUrl}>
                        {user && user.displayName && user.displayName[0]}
                    </Avatar>
                }
                title={title}
                subheader={user && user.displayName}
            />
            <CardMedia
                src={videoURL}
                title={title}
                component="video"
                controls={true}
                preload="metadata"
                poster={thumbnailURL}
                classes={{
                    media: classes.clipMedia,
                    img: classes.clipMedia
                }}
            />
            <CardActions>
                <ClipButtons id={id}/>
            </CardActions>
        </Card>
    );
};

export default React.memo(Clip);