import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const moduleName = 'uploader';
const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
const getState = state => state[moduleName];
const getBindedActions = dispatch => bindActionCreators(actions, dispatch);
const wrapProps = (stateProps, dispatchProps, ownPropws) => ({
    [moduleName]: {
        ...stateProps,
        ...dispatchProps,
    },
    ...ownPropws
});

const connectComponent = connect(getState, getBindedActions);
const connectComponentWithName = connect(getState, getBindedActions, wrapProps);
export const uploader = {
    ...actions,
    reducer,
    saga,
    getState,
    getBindedActions,
    connect: connectComponent,
    connectWithName: connectComponentWithName,
};
export default uploader;
