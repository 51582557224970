import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from "@material-ui/core";

class ProgressWrap extends Component {
    render() {
        const {
            children, progress, shown,
            color = "primary",
            variant,
            size = 30,
        } = this.props;
        return (
            <div style={{position: "relative"}}>
                {children}
                {shown &&
                <CircularProgress
                    variant={variant}
                    color={color}
                    size={size}
                    value={progress}
                    style={{
                        left: '50%',
                        top: '50%',
                        marginTop: -15,
                        marginLeft: -15,
                        position: "absolute",
                        WebkitTransition: 'width 0.3s ease',
                        OTransition: 'width 0.3 ease',
                        transition: 'width 0.3 ease'
                    }}/>}
            </div>
        );
    }
}

ProgressWrap.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node]).isRequired,
    progress: PropTypes.number,
    size: PropTypes.number,
    shown: PropTypes.bool,
    variant: PropTypes.string,
    color: PropTypes.string
};

export default ProgressWrap;