export const AUTH_FORM_VISIBILITY = 'AUTH_FORM_VISIBILITY';
export const AUTH_FORM_LOADING_TEXT = 'AUTH_FORM_LOADING_TEXT';
export const AUTH_FORM_DATA = 'AUTH_FORM_DATA';
export const AUTH_FORM_VALIDATE = 'AUTH_FORM_VALIDATE';
export const AUTH_FORM_IS_PHONE = 'AUTH_FORM_IS_PHONE';
export const AUTH_FORM_IS_EMAIL = 'AUTH_FORM_IS_EMAIL';
export const AUTH_PROVIDERS_REQUEST_SUCCESS = 'AUTH_PROVIDERS_REQUEST_SUCCESS';
export const AUTH_VARIANT_SELECTED = 'AUTH_VARIANT_SELECTED';
export const AUTH_FORM_STEP_SET = 'AUTH_FORM_STEP_SET';
export const AUTH_EMAIL_SIGN_IN = 'AUTH_EMAIL_SIGN_IN';
export const AUTH_EMAIL_SIGN_UP = 'AUTH_EMAIL_SIGN_UP';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_FORM_PHONE_CONFIRMATION = 'AUTH_FORM_PHONE_CONFIRMATION';
export const AUTH_FORM_PHONE_CONFIRM = 'AUTH_FORM_PHONE_CONFIRM';
export const AUTH_FORM_LOADING = 'AUTH_FORM_LOADING';
export const AUTH_LOGIN_ERROR = '@@reactReduxFirebase/LOGIN_ERROR';
export const AUTH_LOGIN_GOOGLE_REQUEST = 'AUTH_LOGIN_GOOGLE_REQUEST';
export const AUTH_LOGIN_FACEBOOK_REQUEST = 'AUTH_LOGIN_FACEBOOK_REQUEST';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_FIREBASE_LOADED = 'AUTH_FIREBASE_LOADED';
export const AUTH_FIREBASE_AUTH = 'AUTH_FIREBASE_AUTH';
export const AUTH_FIREBASE_EMPTY = 'AUTH_FIREBASE_EMPTY';

export const AUTH_SET_PROFILE = 'AUTH_SET_PROFILE';

export const setFirebaseAuth = (auth) => ({type: AUTH_FIREBASE_AUTH, auth});
export const setFirebaseEmpty = () => ({type: AUTH_FIREBASE_EMPTY});
export const setFirebaseLoaded = () => ({type: AUTH_FIREBASE_LOADED});
export const setProfile = (profile) => ({type: AUTH_SET_PROFILE, profile});

export const setLoading = (loading) => ({type: AUTH_FORM_LOADING, loading});
export const setLoadingText = (loadingText) => ({type: AUTH_FORM_LOADING_TEXT, loadingText});
export const setFormShown = () => ({type: AUTH_FORM_VISIBILITY, shown: true});
export const setFormHidden = () => ({type: AUTH_FORM_VISIBILITY, shown: false});

export const setPhoneConfirmation = (confirmation) => ({type: AUTH_FORM_PHONE_CONFIRMATION, confirmation});
export const setProviders = (providers) => ({type: AUTH_PROVIDERS_REQUEST_SUCCESS, providers});
export const setFormVariant = (variant) => ({type: AUTH_VARIANT_SELECTED, variant});
export const setFromStep = (step) => ({type: AUTH_FORM_STEP_SET, step});
export const setStateReset = () => ({type: AUTH_RESET});

export const onEmailSignInRequest = () => ({type: AUTH_EMAIL_SIGN_IN});
export const onEmailSignUpRequest = () => ({type: AUTH_EMAIL_SIGN_UP});
export const onGoogleSignInRequest = () => ({type: AUTH_LOGIN_GOOGLE_REQUEST});
export const onFacebookSignInRequest = () => ({type: AUTH_LOGIN_FACEBOOK_REQUEST});

export const onLogOut = () => ({type: AUTH_LOGOUT});

export const setAuthSuccess = (data) => ({type: AUTH_SUCCESS, data});

export const onFormValidateRequest = () => ({type: AUTH_FORM_VALIDATE});
export const onPhoneConfirmRequest = () => ({type: AUTH_FORM_PHONE_CONFIRM});

export const onFormDataChange = (e) => ({type: AUTH_FORM_DATA, payload: {[e.target.name]: e.target.value}});


