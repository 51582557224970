import {
    CLIPS_FILTER_CLEAR,
    CLIPS_FILTER_TEXT,
    CLIPS_UPLOADER,
    CLIPS_UPLOADER_DATA_UPDATE,
    CLIPS_UPLOADER_FILE_UPDATE,
    CLIPS_UPLOADER_THUMBNAIL_UPDATE
} from "./actions";
import {UPLOADER_TASKS_DONE} from "../uploader/actions";

const defaultState = {
    filter: {
        text: '',
        orderBy: 'publishedAt'
    },
    uploader: {
        shown: false,
        title: '',
        thumbnail: null,
        blobURL: null,
        file: null,
    }
};

export const reducer = (state = defaultState, action) => {

    switch (action.type) {
        case CLIPS_UPLOADER:
            return {...state, uploader: {...state.uploader, shown: action.shown}};
        case CLIPS_UPLOADER_DATA_UPDATE:
            return {...state, uploader: {...state.uploader, ...action.payload}};
        case CLIPS_UPLOADER_THUMBNAIL_UPDATE:
            return {...state, uploader: {...state.uploader, thumbnail: action.thumbnail}};
        case CLIPS_UPLOADER_FILE_UPDATE:
            return {
                ...state, uploader: {
                    ...state.uploader,
                    file: action.file, blobURL: URL.createObjectURL(action.file), shown: true
                }
            };
        case UPLOADER_TASKS_DONE:
            return {...state, uploader: defaultState.uploader};
        case CLIPS_FILTER_TEXT:
            return {...state, filter: {...state.filter, text: action.text}};
        case CLIPS_FILTER_CLEAR:
            return {...state, filter: defaultState.filter};

        default:
            return state;
    }
};