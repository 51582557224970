import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {select} from 'redux-saga/effects';

const moduleName = 'alerts';
const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
const getState = state => state[moduleName];
const selector = select(getState);
const getBindedActions = dispatch => bindActionCreators(actions, dispatch);
const wrapProps = (stateProps, dispatchProps, ownPropws) => ({
    [moduleName]: {
        ...stateProps,
        ...dispatchProps,
    },
    ...ownPropws
});
const connectComponentWithName = connect(getState, getBindedActions, wrapProps);
const connectComponent = connect(getState, getBindedActions);

export const alerts = {
    ...actions,
    reducer,
    saga,
    selector,
    getState,
    actions: getBindedActions,
    connect: connectComponent,
    connectWithName: connectComponentWithName,
};
export default alerts;


