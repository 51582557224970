import React, {useCallback} from 'react';
import ProgressWrap from "../containers/ProgressWrap";
import {Container, TextField, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import clips from "../store/clips";
import {selectClipUploader, selectUploader} from "../store/selectors";
import BrowserDetection from 'react-browser-detection';

const UploaderForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        doUploadRequest,
        onVideoReady, setData
    } = clips.actions(dispatch);

    const {blobURL, title, file, thumbnail} = useSelector(selectClipUploader);
    const {shown, progress} = useSelector(selectUploader);

    const handleTitleChanged = useCallback(setData, []);
    return (
        <Container
            disableGutters={true}>
            <ProgressWrap progress={progress} shown={shown} size={50} color={"secondary"}
                          variant={"determinate"}
            ><BrowserDetection>
                {
                    {
                        safari: () => <img alt="video" id="video-element" className={classes.video}
                                           onLoad={onVideoReady}
                                           src={blobURL}/>,
                        default: () =>
                            <video id="video-element" className={classes.video}
                                   onCanPlay={onVideoReady}
                                   src={blobURL}>
                                <source src={blobURL}/>
                            </video>
                    }
                }
            </BrowserDetection>
            </ProgressWrap>
            <Container className={classes.fieldsContainer}>
                <TextField
                    id="title" name="title" label="Название"
                    color="secondary"
                    fullWidth={true}
                    className={classes.textField}
                    size="small"
                    value={title}
                    onChange={handleTitleChanged}
                />
                <Button
                    disabled={!Boolean(title) || shown}
                    variant="contained" color="secondary"
                    fullWidth={true}
                    onClick={() => doUploadRequest({title, file, thumbnail})}>
                    {!shown ? "Загрузить" : "Видео загружается..."}
                </Button>
            </Container>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({

    fieldsContainer: {
        padding: '0px 5px 5px 5px',
    },
    video: {
        maxHeight: '260px',
        width: '100%',
        objectFit: 'cover',
    },

}));

export default React.memo(UploaderForm);