import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";

const AppDialog = props => {
    const {open, title, text, value, name, type, changeValue, onClose, onAction, addicts} = props;
    let {disabled} = props;
    if (disabled === undefined) disabled = false;
    return (
        <div>
            <Dialog open={open} onClose={onClose} maxWidth={"xs"} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                    {addicts}
                    <TextField
                        autoFocus
                        margin="dense"
                        autoComplete="off"
                        id={name}
                        name={name}
                        label={title}
                        type={type}
                        fullWidth
                        value={value}
                        onChange={changeValue}
                        disabled={disabled}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Отмена
                    </Button>
                    <Button onClick={onAction}>
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AppDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    addicts: PropTypes.node,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    changeValue: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default React.memo(AppDialog);