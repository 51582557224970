import React from 'react';
import {Typography, Container} from '@material-ui/core';

const Rules = () => {
    return (

        <Container style={{maxHeight: "75vh", overflowY: 'auto'}}>
            <Typography component={"div"}>
                <p>Per in illud petentium iudicabit, integre sententiae pro no. Oratio accumsan et mea. Vel in
                    dicant
                    cetero
                    phaedrum, usu populo interesset cu, eum ea facer nostrum pericula. Ius dicat feugiat no, vix cu
                    modo
                    dicat principes. Odio contentiones sed cu, usu commodo prompta prodesset id.</p>
                <p>Ceteros assentior omittantur cum ad. Eam id posse dictas voluptua, veniam laoreet oportere no
                    mea,
                    quis
                    regione suscipiantur mea an. Sea esse deserunt ei, no diam ubique euripidis has. Magna copiosae
                    apeirian
                    ius at. Nec labore cetero theophrastus no, ei vero facer veritus nec.</p>
                <p>Per cu iracundia splendide. Nisl omittam complectitur pro an, quem omnes munere id vix. Eam id
                    posse
                    dictas voluptua, veniam laoreet oportere no mea, quis regione suscipiantur mea an. Lorem ipsum
                    dolor
                    sit
                    amet, an eos lorem ancillae expetenda, vim et utamur quaestio.</p>
                <p>Sea esse deserunt ei, no diam ubique euripidis has. Vix paulo sanctus scripserit ex, te iriure
                    insolens
                    voluptatum qui. An eos iusto solet, id mel dico habemus. Solum vituperata definitiones te vis,
                    vis
                    alia
                    falli doming ea. . Eu eam dolores lobortis percipitur, quo te equidem deleniti disputando.</p>
                <p>Sale liber et vel. Lorem ipsum dolor sit amet, an eos lorem ancillae expetenda, vim et utamur
                    quaestio.
                    Vix paulo sanctus scripserit ex, te iriure insolens voluptatum qui. Nec labore cetero
                    theophrastus
                    no,
                    ei vero facer veritus nec. Solum vituperata definitiones te vis, vis alia falli doming ea.</p>
                <p>Lorem ipsum dolor sit amet, an eos lorem ancillae expetenda, vim et utamur quaestio. An eos iusto
                    solet,
                    id mel dico habemus. Nisl omittam complectitur pro an, quem omnes munere id vix. Mandamus
                    abhorreant
                    deseruisse mea at, mea elit deserunt persequeris at, in putant fuisset honestatis qui.</p>
                <p>Nisl omittam complectitur pro an, quem omnes munere id vix. An nam debet instructior, commodo
                    mediocrem
                    id cum. Per in illud petentium iudicabit, integre sententiae pro no. Postulant assueverit ea
                    his.
                    Vix
                    paulo sanctus scripserit ex, te iriure insolens voluptatum qui.</p>
                <p>Sea esse deserunt ei, no diam ubique euripidis has. Solum vituperata definitiones te vis, vis
                    alia
                    falli
                    doming ea. Ius dicat feugiat no, vix cu modo dicat principes. . Postulant assueverit ea his. Sea
                    esse
                    deserunt ei, no diam ubique euripidis has. Vivendum dignissim conceptam pri ut, ei vel partem
                    audiam
                    sapientem.</p>
                <p>Eu eam dolores lobortis percipitur, quo te equidem deleniti disputando. Vivendum dignissim
                    conceptam
                    pri
                    ut, ei vel partem audiam sapientem. Tation delenit percipitur at vix. Vix paulo sanctus
                    scripserit
                    ex,
                    te iriure insolens voluptatum qui.</p>
                <p>Mandamus abhorreant deseruisse mea at, mea elit deserunt persequeris at, in putant fuisset
                    honestatis
                    qui. An eos iusto solet, id mel dico habemus. Per cu iracundia splendide. Tation delenit
                    percipitur
                    at
                    vix. An eos iusto solet, id mel dico habemus.</p>
            </Typography>
        </Container>

    );
};

export default Rules;