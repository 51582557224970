import {all, delay, takeEvery, put, fork, select} from 'redux-saga/effects';
import {firebase, auth, db} from "../../firebase";
import alerts from "../alerts";
import {
    CLIPS_DELETE_REQUEST,
    CLIPS_TOGGLE_LIKE,
    CLIPS_UPLOADER_VIDEO_READY,
    setThumbnail
} from "./actions";
import {CLIPS_TOGGLE_VISIBLE} from "./actions";
import {CLIPS_UPLOAD_REQUEST} from "./actions";
import {doUpload, UPLOADER_SUCCESS, UPLOADER_TASKS_DONE} from "../uploader/actions";
import {selectClipUploader, selectUserId} from "../selectors";
import {handleErrors} from "../../helpers";


function* onToggleLikeNew() {
    yield takeEvery(CLIPS_TOGGLE_LIKE, function* (action) {
        try {
            const uid = yield auth.currentUser.uid;
            const {key} = action;
            const docRef = db.collection("ClipLikes").doc(key);
            const doc = yield docRef.get();

            if (!doc.exists) {
//                console.log('Doc not exists, creating...');
                yield docRef.set({[uid]: true}, {merge: true})
            } else {
                if (doc.data()[uid]) {
//                    console.log('Like exists, deleting...');
                    yield docRef.set({[uid]: firebase.firestore.FieldValue.delete()}, {merge: true});
//                    yield docRef.update({[uid]: false});
                } else {
//                    console.log('Doc exists, updating...');
                    yield yield docRef.set({[uid]: true}, {merge: true})
                }
            }

        } catch (e) {
            yield* handleErrors(e)
        }


    })
}

function* generateThumbnail() {
    yield takeEvery(CLIPS_UPLOADER_VIDEO_READY, function* (action) {
        try {

            const video = document.querySelector("#video-element"),
                canvas = document.querySelector("#canvas-element"),
                ctx = canvas.getContext("2d");

            let origWidth, origHeight;
            if (video.naturalWidth) {
                origWidth = video.naturalWidth;
                origHeight = video.naturalHeight;
            } else {
                origWidth = video.videoWidth;
                origHeight = video.videoHeight;
            }

            const k = origWidth / origHeight;
            let width;
            if (origWidth < 360) width = origWidth;
            else width = 360;
            const height = width / k;
            canvas.height = height;
            canvas.width = width;
            yield delay(2000);

            /*
                        yield new Promise(resolve => {
                            setTimeout(resolve, 1000);
                        });
            */
            ctx.drawImage(video, 0, 0, width, height)

//            document.querySelector("#testimg").setAttribute('src', canvas.toDataURL());

            function getCanvasBlob(canvas) {
                return new Promise(resolve => {
                    canvas.toBlob(blob => resolve(blob))
                })
            }

            const blob = yield getCanvasBlob(canvas);
//            yield document.querySelector("#testimg").setAttribute('src', URL.createObjectURL(blob));
            yield put(setThumbnail(blob));

        } catch (e) {
            yield* handleErrors(e)
        }


    })
}


function* onToggleVisible() {
    yield takeEvery(CLIPS_TOGGLE_VISIBLE, function* (action) {
        try {
//            const uid = yield auth.currentUser.uid;
            const {key} = action;
            const docRef = db.collection("Clips").doc(key);
            const doc = yield docRef.get();
            if (!doc.exists) {
//                console.log('Doc not exists');
            } else {
                if (doc.data().visible) {
//                    console.log('Visible, setting invisible');
                    yield docRef.update({visible: firebase.firestore.FieldValue.delete()});
//                    yield docRef.update({[uid]: false});
                } else {
//                    console.log('Doc exists, updating...');
                    yield docRef.update({visible: true});
                }
            }

        } catch (e) {
            yield* handleErrors(e)
        }


    })
}

function* onDelete() {
    yield takeEvery(CLIPS_DELETE_REQUEST, function* (action) {
        try {
//            const uid = yield auth.currentUser.uid;
            const {key} = action;
            const docRef = db.collection("Clips").doc(key);
            yield docRef.delete();


        } catch (e) {
            yield* handleErrors(e)
        }


    })
}

function* onUploadRequest() {
    yield takeEvery(CLIPS_UPLOAD_REQUEST, function* (action) {
        try {

            const user = yield select(selectUserId);
            const {title, file, thumbnail} = yield select(selectClipUploader);
            const task = db.collection("Clips").add({user, title, publishedAt: new Date().toJSON(),});
            const docRef = yield task;

            yield put(doUpload(file, `userData/${user}/clips/${docRef.id}/video`, {
                    initiator: 'clips',
                    docRef,
                    field: 'videoURL'
                }, true
            ));
            yield put(doUpload(thumbnail, `userData/${user}/clips/${docRef.id}/thumbnail`,
                {
                    initiator: 'clips',
                    docRef,
                    field: 'thumbnailURL'
                }, false
            ));

        } catch (e) {
            yield* handleErrors(e)
        }


    })
}

function* onUploadComplete() {
    yield takeEvery(UPLOADER_SUCCESS, function* (action) {
            try {
                if (action.sender.initiator === 'clips') {
                    const {url, sender} = action;
                    const {docRef, field} = sender;
                    yield docRef.set({[field]: url}, {merge: true});
                }

            } catch
                (e) {
                yield* handleErrors(e)
            }
        }
    )
}

function* onUploadTasksDone() {
    yield takeEvery(UPLOADER_TASKS_DONE, function* (action) {
            try {
                yield put(alerts.show('success', 'Видео успешно загружено'));

            } catch
                (e) {
                yield* handleErrors(e)
            }
        }
    )
}

export function* saga() {
    const sagas = [
        onToggleLikeNew,
        onToggleVisible,
        onUploadRequest,
        onUploadComplete,
        onDelete,
        onUploadTasksDone,
        generateThumbnail
    ];
    yield all(sagas.map(saga =>
        fork(saga)
    ));


}
