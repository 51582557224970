import {all, fork, put, take, takeEvery} from 'redux-saga/effects';

import {setData, updateData} from "./actions";
import {handleErrors, reduxSagaFirebase} from "../../helpers";
import './types'
import "redux-saga-firebase";
import {FIRESTORE_ADD_LISTENER, FirestoreType, IAddListener, IChange, IDoc} from "./types";

import * as firebase from "firebase";

function* onAddListener() {
    yield takeEvery(FIRESTORE_ADD_LISTENER, function* (action: IAddListener) {

        try {
//            if (window.firestore === undefined) window.firestore = {};
            const channel = reduxSagaFirebase.firestore.channel(action.collection, 'collection' as FirestoreType);
            const collection = action.collection;
            const data: firebase.firestore.QuerySnapshot = yield take(channel);
            const initialDocs: IDoc[] = data.docs.map((doc: firebase.firestore.DocumentData): IDoc => ({
                id: doc.id,
                ...doc.data()
            }))
            // Записать первоначально полученные доди в стейт
            yield put(setData({collection, docs: initialDocs}))
//            window.firestore[action.collection] = data;
            yield takeEvery(channel, function* (data: firebase.firestore.QuerySnapshot) {
                // мониторинг изменений
//                window.firestore[action.collection] = data;

                const changes: IChange[] = yield data.docChanges().map((change: firebase.firestore.DocumentChange) => ({
                    type: change.type,
                    oldIndex: change.oldIndex,
                    newIndex: change.newIndex,
                    data: {
                        id: change.doc.id,
                        ...change.doc.data()
                    }
                }))
                yield put(updateData({
                    collection,
                    changes
                }))

            });
        } catch (e) {
            yield handleErrors(e)
        }
    })

}


export function* saga() {
    const sagas = [
        onAddListener,

    ];
    yield all(sagas.map(saga =>
        fork(saga)
    ));


}