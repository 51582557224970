import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Fab, Link} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import clips from "../store/clips";
import {selectClipsUploaderShown, selectFirebaseProfileIsComplete, selectIsAccountConfirmed} from "../store/selectors";
import AppModal from "../containers/AppModal";
import UploaderForm from "./UploaderForm";
import auth from "../store/auth";
import alerts from "../store/alerts";

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed',
    },


}));

const VideoUploader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {hideUploader, setFile} = clips.actions(dispatch);
    const {setFormShown} = auth.actions(dispatch);
    const isProfileComplete = useSelector(selectFirebaseProfileIsComplete);
    const isConfirmed = useSelector(selectIsAccountConfirmed);
    const modalShown = useSelector(selectClipsUploaderShown);
    const {showPopper} = alerts.actions(dispatch);

    const handleOpen = (e) => {
        if (isProfileComplete && isConfirmed) {
            const videoFile = document.getElementById('videoFile');
            videoFile.click();
        } else showPopper({
            popperAnchorEl: e.currentTarget, popperMessage:
                <div>
                    Чтобы иметь возможность загружать видео необходимо <Link
                    component="button"
                    variant="body2"
                    onClick={setFormShown}
                >{(!isProfileComplete && !isConfirmed)
                    ? "заполнить имя и подтвердить аккаунт"
                    : !isConfirmed ? "подтвердить аккаунт" :
                        !isProfileComplete && "заполнить имя"}
                </Link>
                </div>
        });

    };
    const closeModal = useCallback(hideUploader, []);


    const handleFileInputChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            e.target.value = '';
        }
    };

    return (
        <div>
            <AppModal open={modalShown} onClose={closeModal} title={'Загрузка видео'}>
                <UploaderForm/>
            </AppModal>

            <Fab color="secondary" aria-label="add"
                 className={classes.fab}
                 onClick={handleOpen}>
                <Add/>
            </Fab>
            <input type="file" accept="video/mp4" name="videoFile" id="videoFile" hidden onChange={handleFileInputChange}/>
            {/*    <img id="testimg"/>*/}
            <canvas id="canvas-element" style={{display: "none"}}/>
        </div>
    )
};

//VideoUploader.propTypes = {};

export default React.memo(VideoUploader);