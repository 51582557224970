export const ALERTS_SHOW = 'ALERTS_SHOW';
export const ALERTS_HIDE = 'ALERTS_HIDE';
export const POPPER_SHOW = 'POPPER_SHOW';
export const POPPER_HIDE = 'POPPER_HIDE';

export const show = (alertType, message) => ({
    type: ALERTS_SHOW,
    alertType,
    message
});

export const showPopper = (payload) => ({type: POPPER_SHOW, payload});
export const hidePopper = (payload) => ({type: POPPER_HIDE, payload});
export const hide = () => ({type: ALERTS_HIDE});


// Selectors

