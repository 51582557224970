import {
    FIRESTORE_DOC_CHANGED,
    FIRESTORE_GOT_DATA,
    FirestoreActionTypes, IChange,
    IFirestoreState,
    IFirestoreStateCollectionStatus
} from "./types";

const collectionStatus: IFirestoreStateCollectionStatus = {
    isEmpty: true,
    isLoading: false,
}
const initialState: IFirestoreState = {
    status: {
        Users: collectionStatus,
        Clips: collectionStatus,
        ClipLikes: collectionStatus,
    },
    collections: {
        Users: [],
        Clips: [],
        ClipLikes: [],
    },
    loading: false
};
export const reducer = (state: IFirestoreState = initialState, action: FirestoreActionTypes) => {

    switch (action.type) {
        case FIRESTORE_GOT_DATA:
            return {
                ...state,
                status: {
                    ...state.status, [action.payload.collection]: {
                        isEmpty: false,
                        isLoading: false
                    },
                },
                collections: {
                    ...state.collections,
                    [action.payload.collection]: action.payload.docs

                }
            };
        case FIRESTORE_DOC_CHANGED:
            let docs = [...state.collections[action.payload.collection]];

            action.payload.changes?.forEach((change: IChange) => {
                switch (change.type) {
                    case "added":
                        docs.splice(change.newIndex, 0, change.data);
                        break;
                    case "modified":
                        docs[change.newIndex] = change.data;
                        break;
                    case "removed":
                        docs.splice(change.oldIndex, 1)
                }
            })

            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.collection]: docs
                }
            };
        default:
            return state;
    }
};
