import React from 'react';
import {Button, Grid, Container} from "@material-ui/core";
import {ArrowBackIos} from "@material-ui/icons"
import auth from "../../store/auth";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthForm} from "../../store/selectors";
import ProgressWrap from "../../containers/ProgressWrap";

const AuthStepButtons = () => {
    const dispatch = useDispatch();
    const {variant, step, loading, loadingText} = useSelector(selectAuthForm);
    const {setFromStep, onEmailSignInRequest, onEmailSignUpRequest, onFormValidateRequest, onPhoneConfirmRequest} = auth.actions(dispatch);
    return (
        <Container>
            <Grid container spacing={4} justify='center' style={{padding: '5px'}}>
                <Grid item xs={3}>
                    {step > 0 &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setFromStep(0)
                        }}

                    >
                        <ArrowBackIos/>
                    </Button>
                    }
                </Grid>
                <Grid item xs={9}>
                    {step === 0
                        ?
                        <ProgressWrap color={"secondary"} shown={loading}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={onFormValidateRequest}
                                disabled={loading}
                            >{!loading ? 'Далее' : loadingText}
                            </Button>
                        </ProgressWrap>
                        : step === 1 &&
                        <div>
                            {variant === 'auth-email' &&
                            <ProgressWrap>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={onEmailSignInRequest}
                                    disabled={loading}
                                >{!loading ? 'Далее' : loadingText}
                                </Button>
                            </ProgressWrap>}
                            {variant === 'new-email' &&
                            <ProgressWrap>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={onEmailSignUpRequest}
                                    disabled={loading}
                                >{!loading ? 'Зарегистрироваться' : loadingText}
                                </Button>
                            </ProgressWrap>}
                            {variant === 'phone' &&
                            <ProgressWrap>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={onPhoneConfirmRequest}
                                    disabled={loading}
                                >{!loading ? 'Подтвердить' : loadingText}
                                </Button>
                            </ProgressWrap>}
                        </div>
                    }
                </Grid>
            </Grid>
        </Container>
    );
};

export default AuthStepButtons;