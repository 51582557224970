import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebase = require("firebase/app");
//import { auth } from 'firebase/app';
/*require('firebase/auth/');
require("firebase/firestore");
require("firebase/storage");*/

//require("firebase/functions");

const firebaseConfig = {
    apiKey: "AIzaSyD5zVq2Mc94F5rXSYfh8FAgoRyAUivMbjY",
    authDomain: "enjoy-clip-rate.firebaseapp.com",
    databaseURL: "https://enjoy-clip-rate.firebaseio.com",
//    databaseURL: "http://localhost:8080?ns=enjoy-clip-rate",

    projectId: "enjoy-clip-rate",
    storageBucket: "enjoy-clip-rate.appspot.com",
    messagingSenderId: "897599886228",
    appId: "1:897599886228:web:08bbd70bf9bd5fd7a6b581",
    measurementId: "G-EJ2TBM1Y0N"
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const functions = app.functions('europe-west1');
if (process.env.NODE_ENV === 'development') {
    //   functions.useFunctionsEmulator('https://dev.in23.ml:5001');
}

const db = app.firestore();
//db.settings({host: "localhost:8080", ssl: false});

//firebase.analytics();
const storage = app.storage();
const auth = app.auth();
auth.languageCode = 'ru';
export {
    auth, db, storage, functions, firebase, app as firebaseApp}