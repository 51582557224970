import {createSelector} from "reselect";
import {punto} from "../helpers";

const _ = require('lodash');
// Helpers
const getFiledByKey = arr => key => _.find(arr, elm => elm.id === key);
const getFields = ([key]) => keyFields => keyFields.includes(key);

const objectFieldFilter = (obj) => keyFields =>
    Object.fromEntries(Object
        .entries(obj)
        .filter(field => getFields(field)(keyFields)));


///
// FireStoreMy
export const selectFirestore = state => state.firestore;
export const selectUsers = createSelector(selectFirestore, firestore => firestore.collections.Users);
export const selectLikes = createSelector(selectFirestore, firestore => firestore.collections.ClipLikes);
export const selectClips = createSelector(selectFirestore, firestore => firestore.collections.Clips);

export const selectFirestoreStatus = createSelector(selectFirestore, firestore => firestore.status);
export const selectFirestoreIsLoaded = createSelector(selectFirestoreStatus, status => (!status.Users.isEmpty && !status.ClipLikes.isEmpty && !status.Clips.isEmpty));

//// Зависят от Firestore
export const selectAuth = state => state.auth;
export const selectFirebaseAuth = createSelector(selectAuth, auth => auth.firebase);
export const selectUserId = createSelector(selectFirebaseAuth, auth => auth.uid);
export const selectFirebaseProfile = createSelector(selectAuth, auth => auth.profile);

// ////////////////////////
// Alerts
export const selectAlerts = state => state.alerts;
export const selectAlertsPopper = state => state.alerts.popper;


// Аплоадер
export const selectClipUploader = state => state.clips.uploader;
export const selectClipsUploaderTitle = createSelector(selectClipUploader, uploader => uploader.title);
export const selectClipsUploaderShown = createSelector(selectClipUploader, uploader => uploader.shown);
export const selectUploader = state => state.uploader;
// Clips фильтр, сортировка
export const selectClipsFilter = state => state.clips.filter;
export const selectClipsFilterText = createSelector(selectClipsFilter, filter => filter.text);

// Auth

export const selectAuthForm = createSelector(selectAuth, auth => auth.form);
export const selectAuthProviders = createSelector(selectAuth, auth => auth.providers);


export const selectIsAuthenticated = createSelector(selectFirebaseAuth, auth => !auth.isEmpty);


export const selectIsAdmin = createSelector(selectFirebaseProfile, profile => profile.admin);

// Профиль форма
export const selectProfile = state => state.profile;

///////////////////////////////////
export const selectProfileIsEditing = createSelector(
    [selectProfile, selectFirebaseProfile],
    (profile, firebaseProfile) => !(profile.displayName === firebaseProfile.displayName));
export const selectFirebaseProfileCompletion = createSelector(selectFirebaseProfile, profile => {
    let unfilled = [];
    if (!profile.displayName) unfilled.push('displayName');
    if (!profile.phone) unfilled.push('phone');
    if (!profile.email) unfilled.push('email');
    return unfilled
});
export const selectFirebaseProfileIsComplete = createSelector(selectFirebaseProfile,
    profile => Boolean(profile.displayName));

export const selectAccountConfirmedMethods = createSelector(selectFirebaseAuth,
    auth => {
        let methods = [];
        _.map(auth.providerData, provider => methods.push(provider.providerId));
//        if ((auth.phoneNumber !== profile.phoneNumber) && profile.phoneJustConfirmed) methods.push('phone');
        if (auth.emailVerified) methods.push('email');

        return methods;
    });

export const selectIsAccountConfirmed = createSelector(selectAccountConfirmedMethods,
    methods => methods.includes('phone') || methods.includes('email') || methods.includes('facebook.com'))


export const selectClipsWithLikes = createSelector(
    [selectClips, selectLikes, selectUsers, selectUserId],
    (clips, likes, users, uid) =>
        //     if (clips === undefined || likes === undefined || users === undefined) return undefined;
        _.map(clips, clip => {

            const likedBy = _.compact(_.map(getFiledByKey(likes)(clip.id), (val, key) =>
                (val === false || key === 'id') ? undefined : getFiledByKey(users)(key)));
            return {
                ...clip,
                user: getFiledByKey(users)(clip.user),
                likes: likedBy.length,
                likedBy,
                iLiked: Boolean(getFiledByKey(likedBy)(uid))
            }

        })
);

export const selectClipsForUser = createSelector(
    [selectClipsWithLikes, selectIsAuthenticated, selectIsAdmin, selectUserId],
    (clips, isAuthenticated, isAdmin, uid) => {
        if (!clips) return undefined;
//        console.log(uid)
        if (!isAdmin) return _.filter(clips, clip => (clip.visible === true || (clip.user && clip.user.id === uid)));
        else return clips
    });

export const selectIdsAndOrderClips =
    createSelector(
        [selectClipsForUser, selectClipsFilter],
        (clips, filter) =>
            _.orderBy(
                clips.filter(clip =>
                    (clip.title.toLowerCase().includes(filter.text.toLowerCase())) ||
                    clip.title.toLowerCase().includes(punto(filter.text.toLowerCase())))
                , filter.orderBy, 'desc')
                .map(clip => objectFieldFilter(clip)(['id']))
    );

export const getClipById = (id) =>
    createSelector(
        selectClipsForUser,
        (clips) =>
            _.find(clips, clip => clip.id === id)
    );
