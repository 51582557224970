import React from 'react';
import {Stepper, Step, StepLabel, StepContent, TextField} from "@material-ui/core";
import Socials from "./snippets/Socials";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthForm} from "../store/selectors";
import auth from "../store/auth";
import AuthStepContent from "./snippets/AuthStepContent";
import AuthStepButtons from "./snippets/AuthStepButtons";

const StepLabelText = (props) => (
    <div>
        {
            {
                'new-email': <p>Создайте пароль</p>,
                'auth-email': <p>Введите пароль</p>,
                'auth-provider': <p>Авторизуйтесь</p>,
                'phone': <p>Введите код подтверждения</p>,
            }[props.variant]
        }
    </div>
);


const Auth = () => {
    const dispatch = useDispatch();
    const {variant, username, step, possible} = useSelector(selectAuthForm);
    const {onFormDataChange} = auth.actions(dispatch);
    return (
        <div>
            <Socials/>

            <Stepper activeStep={step} orientation="vertical">
                <Step key={0}>
                    <StepLabel>{!possible
                        ? "Введите E-Mail или номер телефона"
                        : possible === 'email'
                            ? "Введите E-Mail"
                            :
                            possible === 'phone' && "Введите номер телефона"

                    }</StepLabel>
                    <StepContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="username"
                            label={!possible
                                ? "E-Mail/Номер телефона"
                                : possible === 'email'
                                    ? "E-Mail"
                                    :
                                    possible === 'phone' && "Номер телефона"
                            }
                            name="username"
                            value={username}
                            onChange={onFormDataChange}
                            color={"secondary"}
                        />
                    </StepContent>
                </Step>
                <Step key={1}>
                    <StepLabel>
                        <StepLabelText variant={variant}/>
                    </StepLabel>
                    <StepContent>
                        <AuthStepContent/>
                    </StepContent>
                </Step>
            </Stepper>
            {/*TODO: Сброс пароля*/}
            <div id="recaptcha-container"></div>
            <AuthStepButtons/>
        </div>
    );
};

export default React.memo(Auth);