// Clips

export const CLIPS_TOGGLE_LIKE = 'CLIPS_TOGGLE_LIKE';
export const CLIPS_TOGGLE_VISIBLE = 'CLIPS_TOGGLE_VISIBLE';
export const CLIPS_UPLOAD_REQUEST = 'CLIPS_UPLOAD_REQUEST';
export const CLIPS_DELETE_REQUEST = 'CLIPS_DELETE_REQUEST';

export const CLIPS_UPLOADER = 'CLIPS_UPLOADER';
export const CLIPS_UPLOADER_DATA_UPDATE = 'CLIPS_UPLOADER_DATA_UPDATE';
export const CLIPS_UPLOADER_FILE_UPDATE = 'CLIPS_UPLOADER_FILE_UPDATE';
export const CLIPS_UPLOADER_VIDEO_READY = 'CLIPS_UPLOADER_VIDEO_READY';
export const CLIPS_UPLOADER_THUMBNAIL_UPDATE = 'CLIPS_UPLOADER_THUMBNAIL_UPDATE';

export const CLIPS_FILTER_TEXT = 'CLIPS_FILTER_TEXT';
export const CLIPS_FILTER_CLEAR = 'CLIPS_FILTER_CLEAR';

export const toggleLike = (key) => ({type: CLIPS_TOGGLE_LIKE, key: key});
export const setData = (e) => ({type: CLIPS_UPLOADER_DATA_UPDATE, payload: {[e.target.name]: e.target.value}});
export const setFile = (file) => ({type: CLIPS_UPLOADER_FILE_UPDATE, file});
export const setThumbnail = (thumbnail) => ({type: CLIPS_UPLOADER_THUMBNAIL_UPDATE, thumbnail});
export const toggleVisible = (key) => ({type: CLIPS_TOGGLE_VISIBLE, key: key});
export const doDelete = (key) => ({type: CLIPS_DELETE_REQUEST, key: key});
export const onVideoReady = () => ({type: CLIPS_UPLOADER_VIDEO_READY});

export const showUploader = () => ({type: CLIPS_UPLOADER, shown: true});
export const hideUploader = () => ({type: CLIPS_UPLOADER, shown: false});
export const onUploadRequest = () => ({type: CLIPS_UPLOAD_REQUEST});
export const doUploadRequest = ({title, file, thumbnail}) => ({type: CLIPS_UPLOAD_REQUEST, title, file, thumbnail});

export const onFilterTextChange = (e) => ({type: CLIPS_FILTER_TEXT, text: e.target.value});
export const onFilterTextClear = () => ({type: CLIPS_FILTER_CLEAR});