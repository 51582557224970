export const moduleName = 'uploader';

export const UPLOADER_UPLOAD_FILE = 'UPLOADER_UPLOAD_FILE';
export const UPLOADER_PROGRESS = 'UPLOADER_PROGRESS';
export const UPLOADER_PROGRESS_SHOW = 'UPLOADER_PROGRESS_SHOW';
export const UPLOADER_SUCCESS = 'UPLOADER_SUCCESS';
export const UPLOADER_TASK_ADD = 'UPLOADER_TASK_ADD';
export const UPLOADER_TASK_DONE = 'UPLOADER_TASK_DONE';
export const UPLOADER_TASKS_DONE = 'UPLOADER_TASKS_DONE';

//export const setFile = (e) => ({type: UPLOADER_PROGRESS, file: e.target.files[0]});
export const setProgress = (progress) => ({type: UPLOADER_PROGRESS, progress});
export const setProgressShown = (shown) => ({type: UPLOADER_PROGRESS_SHOW, shown});
export const addTask = () => ({type: UPLOADER_TASK_ADD});
export const removeTask = () => ({type: UPLOADER_TASK_DONE});
export const tasksDone = () => ({type: UPLOADER_TASKS_DONE});
//export const onSuccess = (url) => ({type: UPLOADER_SUCCESS, url});

export const doUpload = (file, path, sender = "DEFAULT", monitor = true) => ({
    type: UPLOADER_UPLOAD_FILE,
    file,
    path,
    sender,
    monitor
});


