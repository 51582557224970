import {Dispatch} from 'redux';
import {bindActionCreators} from "redux";

const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
export const auth = {                             // Менять
    ...actions,
    reducer,
    saga,
    actions: (dispatch: Dispatch) => bindActionCreators(actions, dispatch)
};
export default auth;                               // Менять


