import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectAuthForm, selectIsAuthenticated} from "../store/selectors";

import auth from "../store/auth";
import Auth from "./Auth";
import Profile from "./Profile";
import AppModal from "../containers/AppModal";


const ProfileWindow = () => {
    const {shown} = useSelector(selectAuthForm);
    const dispatch = useDispatch();
    const {setFormHidden} = auth.actions(dispatch);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    return (
        <AppModal title={!isAuthenticated ? "Авторизация" : "Профиль"} open={shown} onClose={setFormHidden}>
            {!isAuthenticated ? <Auth/> : <Profile/>}
        </AppModal>
    )
};

export default ProfileWindow;