declare global {
    interface Window {
        firestore: any;
    }
}

export declare enum FirestoreType {
    Collection = 'collection',
    Document = 'document',
}

export * from './actionCreators'
export * from './actionTypes'
export * from './state'


