import {
    FirebaseCollection,
    FIRESTORE_ADD_LISTENER,
    FIRESTORE_DOC_CHANGED,
    FIRESTORE_GOT_DATA,
    IAddListener,
    IDocChanged,
    IPayload,
    ISetData
} from "./types";

export const addListener = (collection: FirebaseCollection): IAddListener => ({
    type: FIRESTORE_ADD_LISTENER,
    collection
});
export const setData = (payload: IPayload): ISetData => ({type: FIRESTORE_GOT_DATA, payload});
export const updateData = (payload: IPayload): IDocChanged => ({type: FIRESTORE_DOC_CHANGED, payload});


