import React from 'react';
import {
    IconButton,
    Toolbar,
    Box,
    Typography,
    Badge,
    AppBar,
    Avatar,
    InputBase,
    Hidden, Fade
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";
import {Search, HighlightOff, Clear} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import auth from "../store/auth";
import {selectClipsFilterText, selectFirebaseProfile, selectIsAuthenticated} from "../store/selectors";
import {makeStyles, fade,} from "@material-ui/core/styles";
import clips from "../store/clips";
import LeftMenu from "./LeftMenu";


const AppHeader = (props) => {
    const {displayName, avatarUrl, admin} = useSelector(selectFirebaseProfile);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const dispatch = useDispatch();
    const {setFormShown} = auth.actions(dispatch);
    const {onFilterTextChange, onFilterTextClear} = clips.actions(dispatch);
    const text = useSelector(selectClipsFilterText);
    const classes = useStyles();
    const searchFieldId = 'search-field';
    /*const width = useWidth();*/
    return (

        <AppBar position="fixed">
            <Toolbar>
                <LeftMenu {...props}/>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <Search/>
                    </div>
                    <InputBase
                        placeholder="Поиск…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        autoFocus
                        onChange={onFilterTextChange}

                        inputComponent={'input'}
                        multiline={false}
                        value={text}
                        inputProps={{'aria-label': 'search', id: searchFieldId}}

                    />
                    <Fade in={Boolean(text)}>
                        <IconButton
                            size={"small"}
                            //                            color={"secondary"}
                            className={classes.clearIcon} onClick={() => {
//                        document.getElementById(searchFieldId).value = '';
                            onFilterTextClear()
                        }}>
                            <Clear/>
                        </IconButton>
                    </Fade>

                </div>
                <Hidden only={['md', 'xl', 'lg']}>
                    <Box style={{flexGrow: 2}}>
                        &nbsp;
                    </Box>
                </Hidden>
                <Hidden only={['sm', 'xs']}>
                    <Box className={classes.appTitle}>
                        <Typography
                            align={"center"}
                            variant={"h5"}
                        >Enjoy! Конкурс</Typography>
                    </Box>
                </Hidden>
                <Hidden only={['sm', 'xs']}>
                    <Box className={classes.displayName}>
                        {displayName}
                    </Box>
                </Hidden>
                <Box className={classes.avatarContainer}>
                    {
                        isAuthenticated && (
                            <IconButton color="inherit" onClick={setFormShown}>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={admin && <FontAwesomeIcon icon={faCrown}/>}
                                >
                                    <Avatar src={avatarUrl}>
                                        {displayName ? displayName.charAt(0) : null}
                                    </Avatar>
                                </Badge>
                            </IconButton>
                        ) /*: (
                            <IconButton color="inherit" onClick={setFormShown}>
                                <ExitToApp/>&nbsp;Вход
                            </IconButton>
                        )*/
                    }
                </Box>
            </Toolbar>
            {/*<span>{`width: ${width}`}</span>*/}
        </AppBar>
    );
};

const useStyles = makeStyles(theme => ({
    displayName: {
        flexGrow: 0,
    },
    appTitle: {
        flexGrow: 2,
    },
    avatarConrainer: {
        flexGrow: 1,
        //paddingRight:'5px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
//        flexGrow: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearIcon: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
//        padding: '5px',
//        width: '15px',
//        height: '15px%',
        right: '2px',
        top: '2px',
        '& > *': {}
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '50%',
        [theme.breakpoints.up('xs')]: {
            width: 140,
            '&:focus': {
                width: 180,
            },
        },
    },
}));

export default AppHeader;
