import {Dispatch} from 'redux';
import {bindActionCreators} from "redux";
import * as types from './types'

const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
export const firestore = {                             // Менять
    ...actions,
    reducer,
    types,
    saga,
    actions: (dispatch: Dispatch) => bindActionCreators(actions, dispatch),
};
export default firestore;                              // Менять

