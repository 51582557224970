import {combineReducers} from 'redux'



import alerts from "./alerts";
import uploader from "./uploader";
import profile from "./profile";
import auth from "./auth";
import clips from "./clips";
import firestore from "./firestore";

const createRootReducer = () => combineReducers({
//  router: connectRouter(history),
    auth: auth.reducer,
    alerts: alerts.reducer,
    profile: profile.reducer,
    uploader: uploader.reducer,
    clips: clips.reducer,

    firestore: firestore.reducer,

});
export default createRootReducer