import React from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import {useSelector} from "react-redux";
import {
    selectFirestoreIsLoaded,
    selectIdsAndOrderClips,
} from "../store/selectors";

import {CircularProgress, Typography} from "@material-ui/core";
import Clip from "./Clip";

const ClipList = () => {
        const loader = <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '80vh',
        }}><CircularProgress color="secondary"/></div>;

        const clips = useSelector(selectIdsAndOrderClips);

        const isLoaded = useSelector(selectFirestoreIsLoaded);
        return (

            <Grid container spacing={2}>
                {!isLoaded ? loader
                    :
                    clips.length
                        ?
                        clips.map((clip) =>
                            <Grid item xs={12} sm={6} md={4} lg={4} key={clip.id}>
                                <Container>
                                    <Paper style={{position: "relative"}} className={'clipPaper'}>
                                        <Clip id={clip.id}/>
                                    </Paper>
                                </Container>
                            </Grid>
                        )
                        : <Typography variant="h4" component="h2" style={{margin:'0 auto'}}  >Ничего не найдено</Typography>}
            </Grid>
        );
    }
;

export default React.memo(ClipList);
