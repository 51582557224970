import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import firestore from "../store/firestore";

const FirestoreConnector = props => {
    const dispatch = useDispatch();
    const {addListener} = firestore.actions(dispatch);
    const {listeners} = props;
    useEffect(() => {
        listeners.map(listener => addListener(listener))
    }, []);
    return (
        <div>
        </div>
    );
};

FirestoreConnector.propTypes = {
    listeners: PropTypes.array.isRequired
};

export default FirestoreConnector;