import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Container} from '@material-ui/core';
import AppHeader from "./components/AppHeader";
import ClipList from "./components/ClipList";
import ProfileWindow from "./components/ProfileWindow";
import VideoUploader from "./components/VideoUploader";
import Alerts from "./components/Alerts";
import {useSelector} from "react-redux";
import {selectFirebaseAuth, selectIsAuthenticated} from "./store/selectors";
import MyTheme from "./theme/myTheme";
import FirestoreConnector from "./components/FirestoreConnector";

const App = () => {
    const {isLoaded} = useSelector(selectFirebaseAuth);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const [brightness, setBrightness] = useState('auto');
    const toggleBrightness = () => {
        const values = ['auto', 'day', 'night'];
        const current = values.indexOf(brightness)
        let newBrightness;
        if (current < 2) newBrightness = values[current + 1];
        else newBrightness = values[0];
        setBrightness(newBrightness)
        localStorage.setItem('themeType', newBrightness);
    }

    useEffect(() => {

        const localBrightness = localStorage.getItem('themeType');
        if (Boolean(localBrightness)) setBrightness(localBrightness);
    }, []);
    /*const trigger = useScrollTrigger({
        threshold: 10,
    });*/

    return (
        <MyTheme brightness={brightness}>
            <FirestoreConnector listeners={['Users', 'Clips', 'ClipLikes']}/>
            <div className="App">
                <Backdrop open={!isLoaded}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                {isLoaded &&
                <div>
                    <Alerts/>
                    <AppHeader toggleBrightness={toggleBrightness} brightness={brightness}/>
                    <Container style={{marginTop: '75px'}}>
                        {/*                    <ForTests/>*/}
                        <ClipList/>
                    </Container>
                    <ProfileWindow/>
                    {/* <MemoTest/>*/}
                    {isAuthenticated && <VideoUploader/>}
                </div>
                }
            </div>
        </MyTheme>
    );
};

export default React.memo(App);