import React from 'react';
import {TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthForm} from "../../store/selectors";
import auth from "../../store/auth";


const AuthStepContent = () => {
    const dispatch = useDispatch();
    const {variant, password, code} = useSelector(selectAuthForm);
    const {onFormDataChange} = auth.actions(dispatch);
    return (
        <div>
            {(variant === 'new-email' || variant === 'auth-email') &&
            <TextField
                variant="outlined"
                margin="normal"
                type={"password"}
                fullWidth
                label="Пароль"
                name="password"
                autoComplete="current-password"
                value={password}
                onChange={onFormDataChange}
            />
            }
            {(variant === 'auth-provider') &&
            <Alert severity="info">У вас уже есть учетная запись, привязанная к одной из
                соцсетей.<br/>
                Авторизуйтесь, используя одну из выделенных иконок выше</Alert>
            }
            {(variant === 'phone') &&
            <div>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Код из SMS"
                    value={code}
                    name="code"
                    onChange={onFormDataChange}
                />
            </div>
            }
        </div>
    );
};

export default React.memo(AuthStepContent);