import {
    PROFILE_DATA,
    PROFILE_EDITING,
    PROFILE_EMAIL_CHANGE_DIALOG, PROFILE_PHONE_CONFIRM_CLEANUP,
    PROFILE_PHONE_CONFIRM_DIALOG,
    PROFILE_SAVING
} from "./actions";
import {cleanupPhone, formatPhone} from "../../helpers";
import {AUTH_FIREBASE_AUTH, AUTH_FIREBASE_EMPTY, AUTH_SET_PROFILE} from "../auth/actions";

const defaultState = {
    displayName: '',
    email: '',
    emailVerified: false,
    phone: '',
    editing: false,
    saving: false,
    avatarUrl: '',
    code: '',
    emailChangeDialogShown: false,
    phoneConfirmDialogShown: false,
    phoneConfirmationReady: false,
    confirmation: {},
    phoneJustConfirmed: false,
};

export const reducer = (state = defaultState, action) => {

    switch (action.type) {
        case AUTH_FIREBASE_AUTH:
            return {
                ...state,
                email: action.auth.email || '',
                phone: action.auth.phoneNumber || '',
                emailVerified: action.emailVerified,
            };
        case AUTH_SET_PROFILE:
            return {
                ...state,
                displayName: action.profile.displayName || '',
                avatarUrl: action.profile.avatarUrl,
                admin: action.profile.admin || false,
            };
        case AUTH_FIREBASE_EMPTY:
            return defaultState;
        case PROFILE_DATA:
            if (Boolean(action.payload.phone)) {
                return {...state, phone: cleanupPhone(formatPhone(action.payload.phone))};
            } else
                return {...state, ...action.payload};
        case PROFILE_EDITING:
            return {...state, editing: action.editing};
        case PROFILE_SAVING:
            return {...state, saving: action.saving};
        case PROFILE_EMAIL_CHANGE_DIALOG:
            return {...state, emailChangeDialogShown: action.shown};
        case PROFILE_PHONE_CONFIRM_DIALOG:
            return {...state, phoneConfirmDialogShown: action.shown};
        case PROFILE_PHONE_CONFIRM_CLEANUP:
            const {phoneConfirmDialogShown, code, confirmation, phoneConfirmationReady} = defaultState;
            return {...state, phoneConfirmDialogShown, code, confirmation, phoneConfirmationReady};
        default:
            return state;
    }
};