import {all} from 'redux-saga/effects';
import uploader from "./uploader";
import profile from "./profile";
import auth from "./auth";
import clips from "./clips";
import firestore from "./firestore";

export default function* rootSaga(getState) {
    yield all([
        auth.saga(),
        clips.saga(),
        profile.saga(),
        uploader.saga(),
        firestore.saga()
    ]);
}
