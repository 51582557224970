import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import clips from "../../store/clips";
import auth from "../../store/auth";
import {
    getClipById,
    selectFirebaseProfileIsComplete, selectIsAccountConfirmed,
    selectIsAdmin,
    selectIsAuthenticated,
    selectUserId
} from "../../store/selectors";
import {IconButton, Link} from "@material-ui/core";
import {DeleteForeverOutlined, Favorite, FavoriteBorder, Visibility, VisibilityOff} from "@material-ui/icons";
import alerts from "../../store/alerts";

const ClipButtons = props => {
    /*   const getClipInstance = (id) => createSelector(getClipById(id), selector => selector);*/
    const {id} = props;

    const dispatch = useDispatch();
    const {toggleLike, toggleVisible, doDelete} = clips.actions(dispatch);
    const {setFormShown} = auth.actions(dispatch);
    const {showPopper, hidePopper} = alerts.actions(dispatch);
    const clip = useSelector(getClipById(id));
    const {iLiked, visible, user, likes} = clip;
    const isAdmin = useSelector(selectIsAdmin);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const uid = useSelector(selectUserId);
    const isConfirmed = useSelector(selectIsAccountConfirmed);
    const isOwner = (user && user.id) === uid;
    const isProfileComplete = useSelector(selectFirebaseProfileIsComplete);
//TODO: Перенести все тексты в отдельный файл
    const handleVisibleMouseOver = (e) => showPopper({
        popperAnchorEl: e.currentTarget, popperMessage:
            <div>
                Видео находится на модерации. Как только мы его проверим, оно станет доступно для просмотра и
                голосования всем пользователям
            </div>
    });
    const handleLikeClick = (e) => {
        if (!isAuthenticated) showPopper({
            popperAnchorEl: e.currentTarget, popperMessage:
                <div>
                    Чтобы иметь возможность голосовать необходимо <Link
                    component="button"
                    variant="body1"
                    onClick={setFormShown}
                >
                    авторизоваться</Link>
                </div>
        });
        else if (!isProfileComplete || !isConfirmed) showPopper({
            popperAnchorEl: e.currentTarget, popperMessage:
                <div>
                    Чтобы иметь возможность голосовать необходимо <Link
                    component="button"
                    variant="body2"
                    onClick={setFormShown}
                >{(!isProfileComplete && !isConfirmed)
                    ? "заполнить имя и подтвердить аккаунт"
                    : !isConfirmed ? "подтвердить аккаунт" :
                        !isProfileComplete && "заполнить имя"}</Link>
                </div>
        });
        else toggleLike(id)
    };

    return (
        <div>
            {visible &&
            <IconButton aria-label="Like!" onClick={handleLikeClick}>
                {iLiked ? <Favorite color={"secondary"}/> : <FavoriteBorder/>} &nbsp;{likes}
            </IconButton>}

            {isOwner && !visible && !isAdmin &&
            <div style={{padding: '12px'}} onMouseOver={handleVisibleMouseOver} onMouseLeave={hidePopper}>
                <VisibilityOff/>
            </div>
            }
            {isAdmin &&
            <>
                <IconButton onClick={() => toggleVisible(id)}>
                    {visible ? <Visibility color={"secondary"}/> : <VisibilityOff/>}
                </IconButton>
                <IconButton onClick={() => doDelete(id)}>
                    <DeleteForeverOutlined color={"error"}/>
                </IconButton>
            </>
            }

        </div>
    );
};


ClipButtons.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ClipButtons;