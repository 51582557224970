import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {select} from 'redux-saga/effects';

const moduleName = 'profile';                       // Менять
const actions = require('./actions');
const reducer = require('./reducers').reducer;
const saga = require('./saga').saga;
const getState = state => state[moduleName];
const selector = select(getState);
const getBindedActions = dispatch => bindActionCreators(actions, dispatch);
const wrapProps = (stateProps, dispatchProps, ownProps) => ({
    [moduleName]: {
        ...stateProps,
        ...dispatchProps,
    },
    ...ownProps
});
const connectWithName = connect(getState, getBindedActions, wrapProps);
const connectComponent = connect(getState, getBindedActions);

export const profile = {                             // Менять
    ...actions,
    reducer,
    saga,
    selector,
    getState,
    actions: dispatch => bindActionCreators(actions, dispatch),
    connect: connectComponent,
    connectWithName
};
export default profile;                              // Менять


