import ReduxSagaFirebase from "redux-saga-firebase";
import {firebaseApp} from "./firebase";
import {put} from "redux-saga/effects";
import alerts from "./store/alerts";

export const formatPhone = phone => phone
    .replace(/^([8,7])/, '+7')
    .replace(/^(2)/, '+7861$&')
    .replace(/^([1,3,4-6,9])/, '+7$&')
export const isPhone = data => {
    return /^\+(\d{1,2})(\d{10})$/.test(data)
};
export const cleanupPhone = data => data.replace(/[^\d,^+]/, '');

export const isPossibleEmail = data => /(^\w+@)|(^[^\d,^+]+)/.test(data);
export const isPossiblePhone = data => /(^\+[\d]+)|(^[\d]{5})|(^89\d+)/.test(data);
export const isEmail = data => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data);
};


export const reduxSagaFirebase = new ReduxSagaFirebase(firebaseApp)

export function* handleErrors(e) {
    let message = e.message;
    switch (e.code) {
        case 'auth/requires-recent-login':
            message = 'Для осуществления данной операции необходима недавняя авторизация. Выйдите из аккаунта, авторизуйтесь снова и повторите';
            break;
        case 'auth/too-many-requests':
            message = 'С вашего устройства поступило слишком много запросов. Операция временно заблокирована, попробуйте позже'
            break;
        case 'auth/credential-already-in-use':
            message = 'Указанные данные уже закреплены за другим аккаунтом';
            break;
        case 'auth/invalid-verification-code':
            message = 'Вы ввели неверный код подтверждения';
            break;
        default:
    }
    console.log(e.code);
    yield put(alerts.show('error', message));
}

export const punto = str => {
    const replacer = {
        "q": "й", "w": "ц", "e": "у", "r": "к", "t": "е", "y": "н", "u": "г",
        "i": "ш", "o": "щ", "p": "з", "[": "х", "]": "ъ", "a": "ф", "s": "ы",
        "d": "в", "f": "а", "g": "п", "h": "р", "j": "о", "k": "л", "l": "д",
        ";": "ж", "'": "э", "z": "я", "x": "ч", "c": "с", "v": "м", "b": "и",
        "n": "т", "m": "ь", ",": "б", ".": "ю", "/": "."
    };

    for (let i = 0; i < str.length; i++) {
        if (replacer[str[i].toLowerCase()] !== undefined) {
            let replace;
            if (str[i] === str[i].toLowerCase()) {
                replace = replacer[str[i].toLowerCase()];
            } else if (str[i] === str[i].toUpperCase()) {
                replace = replacer[str[i].toLowerCase()].toUpperCase();
            }
            str = str.replace(str[i], replace);
        }
    }
    return str;
}